import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { TaskService } from 'src/app/services/task.service';
import { ThemeService } from 'src/app/services/theme.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { UserService } from 'src/app/services/user.service';
import { style } from '@angular/animations';
import { BANK_DETAILS } from 'src/app/services/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  currentUser: any = undefined;
  displayedColumns: string[] = ['number', 'amount', 'quoteEndDate', 'copy'];
  dataSource = new MatTableDataSource<any>();
  tableDataLength: number;
  filteredData: any;
  userid: any;
  darkTheme: boolean;
  invoiceImagePath: string;
  isInIframe: boolean;
  dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  
  constructor(public datepipe: DatePipe, private authService: AuthService,
    private taskService: TaskService,
    private localStorageService: DataService,
    private themeService: ThemeService,
    private us: UserService
  ) {
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;

        this.invoiceImagePath = this.darkTheme ? '../../../assets/images/receipts-dark.svg' : '../../../assets/images/receipts.svg';
      });
      this.isInIframe = this.themeService.isInIframe();    
  }

  ngOnInit(): void {
    const castUser = this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;

      this.authService.user.subscribe(user => this.currentUser = user);
      this.userid = this.currentUser.id;
      this.getBudgets(this.userid);
      castUser.unsubscribe();
    });
  }

  getBudgets(userId: any) {
    this.taskService.getQuotes(userId).subscribe(
      (result: any) => {
        this.filteredData = [];
        this.tableDataLength = result?.quotations?.length > 0 ? result?.quotations?.length: 0;

        if (result?.quotations?.length > 0) {
          this.filteredData = result?.quotations;
          this.dataSource = new MatTableDataSource(this.filteredData);
        }
      },
      (err) => {
        return false;
      }
    );
  }

  generateReceipt(row) {
    this.genPdf(row);
  }

  gst = 0;
  taxType = 'VAT';
  async genPdf(order: any) {
    let reportType = 'Quotation', logo;
    let amount, topUp, total, subtotal_amount;
    const logoimge = '../assets/images/logo.png';
    const exs: any = [];
    if (this.userid) {
      const castUser = this.us.personalInfo(this.userid).subscribe(async (res: any) => {
        this.currentUser = res;

        if (
          this.currentUser?.channelPriceId === undefined &&
          this.currentUser?.country === 'Singapore'
        ) {
          this.gst = environment.gst;
        } else if (this.currentUser?.channelPriceId != undefined) {
          this.gst = this.currentUser?.tax[0]?.taxperc;
          this.taxType = this.currentUser?.tax[0]?.type;
        }

        subtotal_amount = this.dollarUS.format(order?.unitPrice + order?.topUpAmount);
        amount = this.dollarUS.format(order?.unitPrice);
        topUp = this.dollarUS.format(order?.topUpAmount);
        
        total = this.dollarUS.format((order.unitPrice + order?.topUpAmount) + (order.unitPrice + order?.topUpAmount) * (this.gst/100));

        let fullAddress, country;
        if (this.currentUser.address1) {
          fullAddress =
            this.currentUser?.address1 + ' , ' + this.currentUser?.address2;
          country = this.currentUser?.country;
        } else {
          fullAddress = 'Not available';
          country = 'Not available';
        }

        await this.toDataURL(logoimge).then((dataUrl) => {
          logo = dataUrl;
        });

        exs.push([
          {
            columns: [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: 'Item No.',
                style: 'tableHeader',
              },
              {
                width: 150,
                alignment: 'center',
                fontSize: 10,
                text: 'Item Name',
                style: 'tableHeader',
              },
              {
                width: 180,
                alignment: 'center',
                fontSize: 10,
                text: 'Description',
                style: 'tableHeader',
              },
              {
                width: 90,
                alignment: 'right',
                fontSize: 10,
                text: 'Subtotal',
                style: 'tableHeader',
                marginRight: -20,
              }
            ],
          } ,
        ]);

        if(order?.unitPrice > 0) {
          exs.push([ 
            {
              columns: [
                {
                  width: 50,
                  alignment: 'center',
                  fontSize: 10,
                  text: 1,
                  style: 'contents'
                },
                {
                  width: 150,
                  alignment: 'center',
                  fontSize: 10,
                  text: 'Platform subscription',
                  style: 'contents'
                },
               {
                  width: 180,
                  alignment: 'center',
                  fontSize: 10,
                  text: 'Annual subscription to Sat-Tasking platform ' + '(' + this.formatDate(order.quoteStartDate) + ' - ' + this.formatDate(order.quoteEndDate) + ')',
                  style: 'contents'
                },
                {
                  width: 90,
                  fontSize: 10,
                  text: amount,
                  style: 'OrderRight1',
                  marginRight: -20,
                },
              ]
  
            }
          ]);
        }
       if (order?.topUpAmount > 0) {        
        exs.push([
          {
            columns: [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: order?.unitPrice > 0 ? 2 : 1,
                style: 'contents'
              },
              {
                width: 150,
                alignment: 'center',
                fontSize: 10,
                text: 'Top-up amount',
                style: 'contents'
              },
             {
                width: 180,
                alignment: 'center',
                fontSize: 10,
                text: '',
                style: 'contents'
              },
              {
                width: 90,
                fontSize: 10,
                text: topUp,
                style: 'OrderRight1',
                marginRight: -20,
              },
            ]

          }
        ]);
       }

        let docDefinition: any = {
          header: {
            margin: [35, 40, 40, 40],
            columns: [
              {
                image: logo,
                width: 140,
              },
            ],
          },
          content: [
            {
              text: reportType,
              fontSize: 20,
              bold: true,
              alignment: 'right',
              color: '#000',
            },
            {
              margin: [0, 40, 0, 0],
              text: 'Eartheye Space Technologies Pte Ltd',
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#000',
            },
            {
              text: '1 North Bridge Road',
              style: 'CompanyAddress',
            },
            {
              text: '#18-03, High Street Centre',
              style: 'CompanyAddress',
            },
            {
              text: 'Singapore 179094',
              style: 'CompanyAddress',
            },
            {
              columns: [
                [{ text: this.currentUser.name, style: 'CustomerLeft1', margin: [0, 50, 0, 0]}],
              ],
            },
            {
              columns: [
                [
                  {
                    text: this.currentUser?.company,
                    style: 'CustomerLeft1',
                    margin: [0, 0, 0, 0],
                  },
                ],
              ],
            },
            {
              columns: [
                { 
                  text: this.currentUser?.address1, 
                  style: 'CustomerLeft1' 
                },
                { 
                  text:
                      'Date: ' +
                      this.datepipe.transform(new Date(order.createdAt).toDateString(), 'dd-MM-yyyy'),
                  alignment: 'right',
                  style: 'CustomerRight1'  
                }
              ]
            },
            {
              columns: [
                { text: this.currentUser?.address2, style: 'CustomerLeft1', margin: [0, 0, 0, 0], },
                {
                  text: reportType + ' No: ' + order.quotationNumber,
                  style: 'CustomerRight1',
                },
              ]
            },
            {
              columns: [
                [{ text: country, style: 'CustomerLeft1', margin: [0, 0, 0, 5], }]
              ]
            },
            {
              style: 'tableExample',
              table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                  ...exs,
                ],
              },
            },
            {
              columns: [
                [
                  {
                    text: 'Subtotal: ' + subtotal_amount,
                    style: 'OrderRight1',
                  }
                ],
              ],
            },
            {
              columns: [
                [
                  {
                    text: this.taxType + ' (%): ' + this.gst + '.00',
                    style: 'OrderRight1',
                  }
                ],
              ],
            },
            {
              columns: [
                [
                  {
                    text: 'Total: ' + total,
                    style: 'OrderRight1',
                  },
                ],
              ],
            },
            {
              text: 'The quotation is valid for a period of 30 days from the date on the quote.',
              style: 'noteText',
            },
            [{ 
              columns: [
                [
                  {
                    text: 'Telegraphic transfer (TT) details :',
                    style: 'TTdeatial', bold: true, marginTop: 30
                  },
                  {
                    text: 'A/C Holder: '+ BANK_DETAILS.accountHolder,
                    style: 'TTdeatial', marginTop: 10
                  },
                  {
                    text: 'A/C No: ' + BANK_DETAILS.accountNumber,
                    style: 'TTdeatial',
                  },
                  {
                    text: 'SWIFT Code: ' + BANK_DETAILS.SWIFTcode,
                    style: 'TTdeatial',
                  },
                  {
                    text: 'Bank Name: ' + BANK_DETAILS.bankName,
                    style: 'TTdeatial',
                  },
                  {
                    text: 'Bank Address: ' + BANK_DETAILS.bankAddress,
                    style: 'TTdeatial', lineHeight: 1.5
                  }
                ],
              ],
             }],
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            CompanyAddress: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 3, 0, 0],
            },
            OrderRight: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [0, 0, 0, 0],
            },
            CustomerLeft: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 50, 0, 0],
            },
            OrderRight1: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [10, 5, 0, 0],
            },
            CustomerLeft1: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 0, 0, 0],
            },
            CustomerRight1: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [0, 0, 0, 0],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: 'black',
              marginTop: 6,
              marginBottom: 6
            },
            contents: {
              marginTop: 6,
              marginBottom: 6
            },
            TTdeatial: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 5, 0, 0],
            },
            noteText : {
              marginTop: 20,
            }
          },
        }
        if (this.isInIframe) {
          delete docDefinition.header;
          pdfMake.createPdf(docDefinition).download(order.depositNumber + '.pdf');
        } else {
          pdfMake.createPdf(docDefinition).open();
        }
        castUser.unsubscribe();
      });
    }
  }
  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
}
