<div class="container" style="padding-top: 25px;">
  <mat-accordion multi>

    <mat-expansion-panel [expanded]="isFirst">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Tasking Details
        </mat-panel-title>
        <mat-panel-description>
          Enter Details and Select Options for Tasking
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="card pt-0 pr-0">
        <div class="left scrollbar" id="scrollcustom">
          <form class="form-vertical" fxLayout="column" fxLayoutGap="10px" [formGroup]="formGroup"
          *ngIf="!this.budget">

            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Task Strategy<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="strategyRadio" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select task strategy"
                    formControlName="strategyRadio" (selectionChange)="strategyChange($event)"
                    style="color: rgba(0, 0, 0, 0.42)" required>
                    <mat-option *ngFor="let option of strategyOptions; let i = index" [value]="option.value"
                    [disabled]="option.value === 'First Available By Platform' || (currentUser.pointsEligible && (option.value === 'Tip-and-Cue')) || (currentUser.allocated && i !== 0) 
                    || (currentUser?.cpc && option.value === 'Help Select Sensor(s)')">
                      {{ option.name }} 
                      <span style="margin-left: 5px;"
                      [matTooltip]="option.description"
                      matTooltipClass="custom-option-tooltip"
                      >
                      <img width="17px" [src]="tooltipImagePath" style="margin-top: -2px;"/>
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="fields.strategyRadio.errors && fields.strategyRadio.touched">
                <div *ngIf="fields.strategyRadio.errors.required" class="error-valid">Task Strategy is required</div>
              </div>
            </div>

            <div class="purposes" style="margin: 0" *ngIf="taskStrategy === 'Tip-and-Cue'">
              <label>Sub Strategies<span>*</span></label>
              <div style="text-align:center;">
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select task sub strategy"
                    formControlName="subStrategyRadio" (selectionChange)="subStrategyChange($event)"
                    style="color: rgba(0, 0, 0, 0.42)">
                    <mat-option *ngFor="let option of subStrategyOptions; let i = index" [value]="option.value">
                      {{ option.name }} 
                      <span style="margin-left: 5px;"
                      [matTooltip]="option.description"
                      matTooltipClass="custom-option-tooltip">
                      <img width="17px" [src]="tooltipImagePath"/>
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="fields.subStrategyRadio?.errors && fields.subStrategyRadio.touched">
                <div *ngIf="fields.subStrategyRadio?.errors.required" class="error-valid">Task sub Strategy is required</div>
              </div>
            </div>


            <div class="form-lble" style="margin: 0px;">
              <label>Project Name<span>*</span></label>
              <input type="name" placeholder="Your project name" [matAutocomplete]="auto" (keyup)="nameChng($event, 1)"
                formControlName="project" required (keydown)="Space($event)" [readonly]="this.retask" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let projects of filteredProjects | async" [value]="projects?.project"
                  (click)="setProjectid(projects)">
                  <span>{{projects.project}}</span>
                </mat-option>
              </mat-autocomplete>
              <div *ngIf="fields.project.errors && fields.project.touched">
                <div *ngIf="fields.project.errors.required" class="error-valid">Project Name is required</div>
                <div *ngIf="fields.project.hasError('minlength')" class="error-valid">At least 4 characters long</div>
                <div *ngIf="fields.project.hasError('maxlength')" class="error-valid">Maximum 25 characters long</div>
              </div>
            </div>

            <div class="form-lble" style="margin: 0 0 20px 0;">
              <label>Task Name<span>*</span></label>
              <input type="name" (keyup)="nameChng($event, 2)" placeholder="Your task name" formControlName="taskname"
                required (keydown)="Space($event)" *ngIf="!this.retask" maxlength="35" />
                <input type="name" (keyup)="nameChng($event, 2)" placeholder="Your task name" formControlName="taskname"
                required (keydown)="Space($event)" *ngIf="this.retask" readonly/>

              <div *ngIf="fields.taskname.errors && fields.taskname.touched">
                <div *ngIf="fields.taskname.errors.required" class="error-valid">Task Name is required</div>
                <div *ngIf="fields.taskname.hasError('minlength')" class="error-valid">At least 5 characters long</div>
                <!-- <div *ngIf="fields.taskname.hasError('maxlength')" class="error-valid">Maximum 35 characters long</div> -->
              </div>
            </div>
          
           
            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Intent<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="intent" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select intent"
                    formControlName="intent" (selectionChange)="intentChange($event.value)"
                    style="color: rgba(0, 0, 0, 0.42)" required>
                    <mat-option *ngFor="let option of intentOption" [value]="option" [disabled]="currentUser.allocated && option.key !== 'internalUse'">
                      {{ option.name }}

                      <span style="margin-left: 5px;" *ngIf="option?.description">
                        <img width="17px" [src]="tooltipImagePath"
                          [matTooltip]="option.description"/>
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="fields.intent.errors && fields.intent.touched">
                <div *ngIf="fields.intent.errors.required" class="error-valid">Intent is required</div>
              </div>
            </div>
            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Purpose<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="purpose" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select purpose"
                    formControlName="purpose" (selectionChange)="onPurposeChange($event.value)"
                    style="color: rgba(0, 0, 0, 0.42)" required>
                    <mat-option *ngFor="let option of purposeOption; let i = index" [value]="option" [disabled]="(taskStrategy === 'AIS Included' && option.purpose !== 'Maritime Security')">
                      {{ option.purpose }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="fields.purpose.errors && fields.purpose.touched">
                <div *ngIf="fields.purpose.errors.required" class="error-valid">Purpose is required</div>
              </div>
            </div>
            <div *ngIf="this.others">
              <label>Description<span>*</span></label>
              <input type="name" placeholder="Your description" formControlName="description" required minlength="4"
                (keydown)="Space($event)" (keyup)="descChng($event)">

              <div *ngIf="fields.description.errors && fields.description.touched">
                <div *ngIf="fields.description.errors.required" class="error-valid">Description is required</div>
                <div *ngIf="fields.description.errors?.minlength" class="error-valid">At least 4 characters long</div>
                <div *ngIf="fields.description.hasError('maxlength')" class="error-valid">Maximum 50 characters long
                </div>
              </div>
            </div>

            <div class="purposes" style="margin: 0 0 20px 0;" *ngIf="taskStrategy=='Help Select Sensor(s)'">
              <label>Use Case/ Application<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="useCase" [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select use case"
                    formControlName="useCase" (selectionChange)="useCaseChange($event.value)"
                    style="color: rgba(0, 0, 0, 0.42)">
                    <mat-option *ngFor="let option of useCaseOption; let i = index" [value]="option" [disabled]="(taskStrategy === 'AIS Included' && i !== 10)">
                      {{ option.useCase }}
                      <span style="margin-left: 5px;" *ngIf="option.description"
                      [matTooltip]="option.description"
                      matTooltipClass="custom-option-tooltip"
                      >
                        <img width="17px" [src]="tooltipImagePath"  style="margin-top: -2px;" />
                          <img *ngIf="option.value === 'Tip-and-Cue'"
                          style="margin-left: 10px; margin-top: -1px;"
                          width="30px"
                          src="../../../assets/images/beta-version.png"
                        />
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="fields.useCase.touched">
                <div *ngIf="fields.useCase.value === null" class="error-valid">Use Case is required</div>
              </div>
            </div>
            <div *ngIf="taskStrategy=='Help Select Sensor(s)'">
              <label>Use Case Description</label>
              <input type="name" placeholder="Your description" formControlName="useCaseDescription" (keyup)="usecasedescChng($event)" [readonly]="this.retask" 
              >
              <div *ngIf="fields.useCaseDescription.errors && fields.useCaseDescription.touched">
                <div *ngIf="fields.useCaseDescription.errors?.maxlength" class="error-valid">Please enter your use case description within 50 characters
                </div>
              </div>
            </div>

            <div class="form-lble" style="margin: 0 0 20px 0;">
              <div class="select-head">
                <label> Select Target*
                  <span style="padding: 0 0 0 0; display: block;">Identify your target (drop a pin, draw an area and
                    right click to complete or enter Lng, Lat coordinates)
                    on the map</span>
                </label>
              </div>
              <div class="select-details">
                <div class="map-details">
                  <input matInput type="text" placeholder="Select your desired area" formControlName="target"
                  [readonly]="true" value="{{target.geoJSON}}" style="
                      border: 1px solid #98a2b3;
                      padding: 0 0 0 11px;
                      color: #000;
                      font-weight: 500;
                      position: relative;
                    " />
                  <div class="icon">
                    <span [matTooltipClass]="'target-tooltip'" matTooltip="{{ target.geoJSON }}">
                      <img width="20px" height="20" [src]="tooltipImagePath"/>
                    </span>
                  </div>
                </div>
              </div>
            </div>

           

            <div *ngIf="taskStrategy=='Tip-and-Cue' && subStrategy == 'Tip-and-Cue'" class="purposes" style="margin: 0 0 20px 0;">
              <div class="form-lble" style="margin: 0px;">
                <div class="row mt-2">
                  <div class="col-12"> 
                    <label>Based On<span>*</span></label>
                  </div>
                  <div class="col-12 mt-1 mb-3">
                    <mat-radio-group   aria-label="Select an option" formControlName="tipAndCueBasedOn" (change)="tipAndCueTypeChange($event)" [disabled]="this.retask">
                      <mat-radio-button checked name="tipAndCueBasedOnRadio" value="Observation" style="margin-left:15px;margin-right:12px;">Observation</mat-radio-button>
                      <mat-radio-button name="tipAndCueBasedOnRadio"  value="Rules">Rule</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <div style="text-align:center;" *ngIf="taskStrategy === 'Tip-and-Cue' && tipAndCueBasedOn === 'Observation'">
                <input type="name" *ngIf="this.retask" formControlName="observation" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [(ngModel)]="observation"  [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select observation"
                    formControlName="observation"
                    style="color: rgba(0, 0, 0, 0.42);"  (selectionChange)="onObservationChange($event.value)">
                    <mat-option *ngFor="let option of observationOptions" [value]="option" [selected]="observation?.name == option.name"
                    [disabled]="!option?.enabled">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="!observation && fields.observation.touched && taskStrategy === 'Tip-and-Cue' && tipAndCueBasedOn === 'Observation' && !this.retask ">
                <div class="error-valid">Observation is required</div>
              </div> 
              <div style="text-align:center;" *ngIf="taskStrategy === 'Tip-and-Cue' && tipAndCueBasedOn === 'Rules' ">
                <input type="name" *ngIf="this.retask" formControlName="rule" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [(ngModel)]="rule"  [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select rule"
                    formControlName="rule" 
                    style="color: rgba(0, 0, 0, 0.42);"  (selectionChange)="onRulesChange($event.value)">
                    <mat-option *ngFor="let option of rulesOptions" [value]="option.rule">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="!rule && fields.rule.touched && taskStrategy === 'Tip-and-Cue' && tipAndCueBasedOn === 'Rules' && !this.retask">
                <div  class="error-valid">Rule is required</div>
              </div> 
            </div>

            <div style="margin: 0 0 20px 0;">
              <label>Frequency<span>*</span></label>
              <input type="name" *ngIf="this.retask"
              formControlName="frequency" required [readonly]="this.retask" />
              <mat-form-field appearance="outline" class="frequ" *ngIf="!this.retask">
                <mat-select [disableOptionCentering]="true" panelClass="matopens" placeholder="Select frequency"
                  formControlName="frequency" (selectionChange)="onOptionChange($event.value)"
                  style="color: rgba(0, 0, 0, 0.42)" required>

                  <mat-option *ngFor="let option of frequencyOption; let i = index" [value]="option.name"
                  [disabled]="((taskStrategy === 'Tip-and-Cue' || taskStrategy === 'Outcome By Platform') && i === 1) || (taskStrategy === 'AIS Included' &&( i === 1 || i===0))" >

                    {{ option.name }}

                    <span [matTooltip]="getTooltip(option.name)" matTooltipClass="custom-option-tooltip">
                    <img style="margin-left: 5px;" width="17px" height="17" [src]="tooltipImagePath"/>
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="fields.frequency.errors && fields.frequency.touched">
                <div *ngIf="fields.frequency.errors.required" class="error-valid">Frequency is required</div>
              </div>
            </div>

            <div *ngIf="selectedOption === 1 && clickFrequency">
              <label>Repeat</label>
              <input type="name" *ngIf="this.retask"
               [value]="this.target.freqOption" [readonly]="this.retask" />
              <mat-form-field style="width: 100%" appearance="outline" *ngIf="!this.retask">
                <mat-select [disableOptionCentering]="true" panelClass="matopens" placeholder="Choose repetition"
                  (selectionChange)="freqChange($event.value)" [value]="TASKFREQUENCY[1]">
                  <mat-option *ngFor="let option of TASKFREQUENCY; let i = index" [value]="option">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="selectedOption === 1 && subDaily">
              <label>Sub-daily Count</label>
              <input type="name" *ngIf="this.retask"
               [value]="this.target.subDailyCount" [readonly]="this.retask" />
              <mat-form-field style="width: 100%" appearance="outline" *ngIf="!this.retask">
                <mat-select [disableOptionCentering]="true" panelClass="matopens" placeholder="Choose sub-daily count"
                  (selectionChange)="recurrentCountChange($event.value)" [value]="dailyCount[0]">
                  <mat-option *ngFor="let option of dailyCount" [value]="option">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>          

            <div *ngIf="selectedOption === 0 && clickFrequency">
              <label>Date</label>
              <mat-form-field appearance="outline">
                <mat-label>Choose date</mat-label>
                <input matInput [matDatepicker]="datepicker" [matDatepickerFilter]="DateFilter" [formControl]="date"
                  readonly [min]="todayDate" placeholder="Choose a date"
                  (dateChange)="handleDateChange($event, 1)" style="border: 0; padding: 0;" />
                <mat-datepicker-toggle matSuffix [for]="datepicker" placeholder="Choose a date"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="select-daterange" *ngIf="
                (selectedOption === 1 || selectedOption === 2) &&
                clickFrequency
              ">
              <label>Date Range </label>
              <mat-form-field appearance="outline">
                <mat-label>Choose date range</mat-label>
                <mat-date-range-input [class.custom-date-range]="!darkTheme" placeholder="Select dates" [formGroup]="range" [rangePicker]="picker"
                  [min]="todayDate" [max]="maxDate" [comparisonStart]="range.value.start"
                  [comparisonEnd]="range.value.end" style="padding: 0">
                  <input matStartDate formControlName="start" placeholder="Start date" readonly
                    (dateChange)="handleDateChange($event, 2)" style="border: 0; height: 100%; padding: 0" />

                  <input matEndDate formControlName="end" placeholder="End date" readonly
                  [ngClass]="{ 'date-input': (selectedOption === 2 && taskStrategy != 'Tip-and-Cue') }"
                    (dateChange)="handleDateChange($event, 3)" style="border-right: 0;border-left: 0; padding: 0;" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="
                    range.controls.start.hasError('matStartDateInvalid')
                  ">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
              <span *ngIf="dateFlag" style="color: red; text-align: left; font-size: 12px;font-weight: 600;">Start date and End date
                shouldn't be same</span>
              <span *ngIf="range.value.end === null" style="color: red; text-align: left; font-size: 12px;font-weight: 600;">Please Select
                End date</span>
            </div>
            
            <div *ngIf="clickFrequency && selectedOption != -1">
              <label>Start Time (UTC)<span>*</span></label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  matInput
                  [ngxTimepicker]="timepicker"
                  readonly
                  style="border: none !important; height: 18px;padding-left: 1px !important;"
                  placeholder="Select start time"
                  formControlName="startTime"
                />
              
                <mat-icon
                  matSuffix
                  (click)="openFromIcon(timepicker)"
                  [ngClass]="{'icon-date' : darkTheme}"
                  style="color: #7f7f7f; cursor: pointer;"
                  matTooltip="Local time to UTC converter"
                  >schedule</mat-icon
                >
                <ngx-material-timepicker #timepicker (timeSet)="onTimeSet($event)"  [defaultTime]="currentLocalTime"></ngx-material-timepicker>
              </mat-form-field>

              <div *ngIf="fields.startTime.touched">
              <div *ngIf="fields.startTime.errors && fields.startTime.errors.required" class="error-valid">Start Time is required</div>
                <div *ngIf="isPast && !fields.startTime.errors" class="error-valid">The start time needs to be ahead of the current time</div>
              </div>
            </div>

            <div *ngIf="clickFrequency && selectedOption != -1">
              <label>End Time (UTC)<span>*</span></label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  matInput
                  [ngxTimepicker]="pickers"
                  readonly
                  style="border: none !important; height: 18px;padding-left: 1px !important;"
                  placeholder="Select end time"
                  formControlName="endTime"
                />
              
                <mat-icon
                  matSuffix
                  (click)="openDate(pickers)"
                  style="color: #7f7f7f; cursor: pointer;"
                  [ngClass]="{'icon-date' : darkTheme}"
                  matTooltip="Local time to UTC converter"
                  >schedule</mat-icon
                >
                <ngx-material-timepicker #pickers (timeSet)="onEndTimeSet($event)" [defaultTime]="currentLocalTime"></ngx-material-timepicker>
              </mat-form-field>

              <div *ngIf="fields.endTime.touched">
                <div *ngIf="fields.endTime.errors && fields.endTime.errors.required" class="error-valid">End Time is required</div>
                <div *ngIf="timeError < 4 && !isEndPast && selectedOption === 0 && !fields.endTime.errors" class="error-valid">TOI must have four hrs between the start and end times</div>
                <div *ngIf="timeError < 24 && selectedOption != 0 && !fields.endTime.errors" class="error-valid">Start and end dates/times must be at least 24 hours apart</div>
                <div *ngIf="isEndPast && selectedOption === 0 && !fields.endTime.errors" class="error-valid">The end time needs to be ahead of the start time</div>
              </div>
            </div>
          </form>

          <form class="form-vertical" fxLayout="column" fxLayoutGap="10px" [formGroup]="budgetForm"
          *ngIf="this.budget">         

            <div class="form-lble" style="margin: 0 0 20px 0;">
              <div class="select-head">
                <label> Select Target*
                  <span style="padding: 0 0 0 0; display: block;">Identify your target (drop a pin, draw an area and
                    right click to complete or enter Lng, Lat coordinates)
                    on the map</span>
                </label>
              </div>
              <div class="select-details">
                <div class="map-details">
                  <input matInput type="text" placeholder="Select your desired area" formControlName="target"
                  [readonly]="true" value="{{target.geoJSON}}" style="
                      border: 1px solid #98a2b3;
                      padding: 0 0 0 11px;
                      color: #000;
                      font-weight: 500;
                      position: relative;
                    " />
                  <div class="icon">
                    <img width="20px" height="20" [src]="tooltipImagePath"
                    matTooltip="{{ target.geoJSON }}"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Intent<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="intent" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select intent"
                    formControlName="intent" (selectionChange)="intentChange($event.value)"
                    style="color: rgba(0, 0, 0, 0.42)" required>
                    <mat-option *ngFor="let option of intentOption" [value]="option" [disabled]="currentUser.allocated && option.key !== 'internalUse'">
                      {{ option.name }}

                      <span style="margin-left: 5px;" *ngIf="option?.description">
                        <img width="17px" [src]="tooltipImagePath"
                          [matTooltip]="option.description"/>
                      </span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="budgetFields.intent.errors && budgetFields.intent.touched">
                <div *ngIf="budgetFields.intent.errors.required" class="error-valid">Intent is required</div>
              </div>
            </div>
          
            <div style="margin: 0 0 20px 0;">
              <label>Frequency<span>*</span></label>
              <input type="name" *ngIf="this.retask"
              formControlName="frequency" required [readonly]="this.retask" />
              <mat-form-field appearance="outline" class="frequ" *ngIf="!this.retask">
                <mat-select [disableOptionCentering]="true" panelClass="matopens" placeholder="Select frequency"
                  formControlName="frequency" (selectionChange)="onOptionChange($event.value)"
                  style="color: rgba(0, 0, 0, 0.42)" required>
          
                  <mat-option *ngFor="let option of frequencyOption; let i = index" [value]="option.name">
          
                    {{ option.name }}
          
                    
                    <span [matTooltip]="getTooltip(option.name)" matTooltipClass="custom-option-tooltip">
                      <img style="margin-left: 5px;" width="17px" height="17" [src]="tooltipImagePath"
                      />
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="budgetFields.frequency.errors && budgetFields.frequency.touched">
                <div *ngIf="budgetFields.frequency.errors.required" class="error-valid">Frequency is required</div>
              </div>
            </div>
          
            <div *ngIf="selectedOption === 1 && clickFrequency">
              <label>Repeat</label>
              <input type="name" *ngIf="this.retask"
               [value]="this.target.freqOption" [readonly]="this.retask" />
              <mat-form-field style="width: 100%" appearance="outline" *ngIf="!this.retask">
                <mat-select [disableOptionCentering]="true" panelClass="matopens" placeholder="Choose repetition"
                  (selectionChange)="freqChange($event.value)" [value]="TASKFREQUENCY[1]">
                  <mat-option *ngFor="let option of TASKFREQUENCY" [value]="option">
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          
            <div *ngIf="selectedOption === 1 && subDaily">
              <label>Sub-daily Count</label>
              <input type="name" *ngIf="this.retask"
               [value]="this.target.subDailyCount" [readonly]="this.retask" />
              <mat-form-field style="width: 100%" appearance="outline" *ngIf="!this.retask">
                <mat-select [disableOptionCentering]="true" panelClass="matopens" placeholder="Choose sub-daily count"
                  (selectionChange)="recurrentCountChange($event.value)" [value]="dailyCount[0]">
                  <mat-option *ngFor="let option of dailyCount" [value]="option">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          
            <div *ngIf="selectedOption === 0 && clickFrequency">
              <label>Date</label>
              <mat-form-field appearance="outline">
                <mat-label>Choose date</mat-label>
                <input matInput [matDatepicker]="datepicker" [matDatepickerFilter]="DateFilter" [formControl]="date"
                  readonly [min]="todayDate" placeholder="Choose a date" value="minDate"
                  (dateChange)="handleDateChange($event, 1)" style="border: 0; padding: 0;" />
                <mat-datepicker-toggle matSuffix [for]="datepicker" placeholder="Choose a date"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
              </mat-form-field>
            </div>
          
            <div class="select-daterange" *ngIf="
                (selectedOption === 1 || selectedOption === 2) &&
                clickFrequency
              ">
              <label>Date Range </label>
              <mat-form-field appearance="outline">
                <mat-label>Choose date range</mat-label>
                <mat-date-range-input [class.custom-date-range]="!darkTheme" placeholder="Select dates" [formGroup]="range" [rangePicker]="picker"
                  [min]="todayDate" [max]="maxDate" [comparisonStart]="range.value.start"
                  [comparisonEnd]="range.value.end" style="padding: 0">
                  <input matStartDate formControlName="start" placeholder="Start date" readonly
                    (dateChange)="handleDateChange($event, 2)" style="border: 0; height: 100%; padding: 0" />
          
                  <input matEndDate formControlName="end" placeholder="End date" readonly
                  [ngClass]="{ 'date-input': (selectedOption === 2 && taskStrategy != 'Tip-and-Cue') }"
                    (dateChange)="handleDateChange($event, 3)" style="border-right: 0;border-left: 0; padding: 0;" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
          
                <mat-error *ngIf="
                    range.controls.start.hasError('matStartDateInvalid')
                  ">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
              <span *ngIf="dateFlag" style="color: red; text-align: left; font-size: 12px;font-weight: 600;">Start date and End date
                shouldn't be same</span>
              <span *ngIf="range.value.end === null" style="color: red; text-align: left; font-size: 12px;font-weight: 600;">Please Select
                End date</span>
            </div>

            <div *ngIf="clickFrequency && selectedOption != -1">
              <label>Start Time (UTC)<span>*</span></label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  matInput
                  [ngxTimepicker]="timepicker"
                  readonly
                  style="border: none !important; height: 18px;padding-left: 1px !important;"
                  placeholder="Select start time"
                  formControlName="startTime"
                />
              
                <mat-icon
                  matSuffix
                  (click)="openFromIcon(timepicker)"
                  [ngClass]="{'icon-date' : darkTheme}"
                  style="color: #7f7f7f; cursor: pointer;"
                  matTooltip="Local time to UTC converter"
                  >schedule</mat-icon
                >
                <ngx-material-timepicker #timepicker (timeSet)="onTimeSet($event)"></ngx-material-timepicker>
              </mat-form-field>
          
              <div *ngIf="budgetFields.startTime.touched">
              <div *ngIf="budgetFields.startTime.errors && budgetFields.startTime.errors.required" class="error-valid">Start Time is required</div>
                <div *ngIf="isPast && !budgetFields.startTime.errors" class="error-valid">The start time needs to be ahead of the current time</div>
              </div>
            </div>
          
            <div *ngIf="clickFrequency && selectedOption != -1">
              <label>End Time (UTC)<span>*</span></label>
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  matInput
                  [ngxTimepicker]="pickers"
                  readonly
                  style="border: none !important; height: 18px;padding-left: 1px !important;"
                  placeholder="Select end time"
                  formControlName="endTime"
                />
              
                <mat-icon
                  matSuffix
                  (click)="openDate(pickers)"
                  style="color: #7f7f7f; cursor: pointer;"
                  [ngClass]="{'icon-date' : darkTheme}"
                  matTooltip="Local time to UTC converter"
                  >schedule</mat-icon
                >
                <ngx-material-timepicker #pickers (timeSet)="onEndTimeSet($event)" [defaultTime]="currentLocalTime" [defaultTime]="currentLocalTime"></ngx-material-timepicker>
              </mat-form-field>

              <div *ngIf="budgetFields.endTime.touched">
                <div *ngIf="budgetFields.endTime.errors && budgetFields.endTime.errors.required" class="error-valid">End Time is required</div>
                <div *ngIf="timeError < 4 && !isEndPast && selectedOption === 0 && !budgetFields.endTime.errors" class="error-valid">TOI must have four hrs between the start and end times</div>
                <div *ngIf="timeError < 24 && selectedOption != 0 && !budgetFields.endTime.errors" class="error-valid">Start and end dates/times must be at least 24 hours apart</div>
                <div *ngIf="isEndPast && selectedOption === 0 && !budgetFields.endTime.errors" class="error-valid">The end time needs to be ahead of the start time</div>
              </div>
            </div>
            <div class="purposes" style="margin: 0 0 20px 0;">
              <label class="card-label">Sensor Type<span>*</span></label>
              <div style="text-align:center;">
                <mat-form-field appearance="outline">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select sensor type"
                    formControlName="sensorType" (selectionChange)="budgetSensorChange($event)" style="color: rgba(0, 0, 0, 0.42)"
                    >
                    <mat-option *ngFor="let option of budgetSensorType; let i = index" [value]="option"
                    >
                      {{option.sensor =='RF'? capitalizeFirstLetter('RF- comms'): capitalizeFirstLetter(option.sensor) }}
                    
                      <img style="margin-left: 10px;" width="17px" height="17" [src]="tooltipImagePath"
                      [matTooltip]="option.description"/>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="budgetFields.sensorType.errors && budgetFields.sensorType.touched">
                <div *ngIf="budgetFields.sensorType.errors.required" class="error-valid">Sensor type is required</div>
              </div>
            </div>
            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Resolution<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="resolution" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select resolution"
                    formControlName="resolution" style="color: rgba(0, 0, 0, 0.42)" required (selectionChange)="budgetResolutionChange($event)">
                    <mat-option *ngFor="let option of budgetInputs?.resolutionArr" [value]="option">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="budgetFields.resolution.errors && budgetFields.resolution.touched">
                <div *ngIf="budgetFields.resolution.errors.required" class="error-valid">Resolution is required</div>
              </div>
            </div>

            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Tasking Tier<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="taskingTier" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select tasking tier"
                    formControlName="taskingTier" style="color: rgba(0, 0, 0, 0.42)" required (selectionChange)="budgetTierChange($event)">
                    <mat-option *ngFor="let option of budgetInputs?.tierArr" [value]="option">
                      {{capitalizeFirstLetter(option.name)  }}

                      <img *ngIf="option?.tierToolTip" style="margin-left: 10px;" width="17px" height="17" [src]="tooltipImagePath"
                      [matTooltip]="option.tierToolTip"/>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="budgetFields.taskingTier.errors && budgetFields.taskingTier.touched">
                <div *ngIf="budgetFields.taskingTier.errors.required" class="error-valid">Tasking tier is required</div>
              </div>
            </div>

            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Cloud Cover<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="cloudCover" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select cloud cover"
                    formControlName="cloudCover" style="color: rgba(0, 0, 0, 0.42)" >
                    <mat-option *ngFor="let option of budgetInputs?.cloudCoverKeys" [value]="option">
                      {{capitalizeFirstLetter(option)  }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="budgetFields.cloudCover.errors && budgetFields.cloudCover.touched">
                <div *ngIf="budgetFields.cloudCover.errors.required" class="error-valid">Cloud cover is required</div>
              </div>
            </div>

            <!-- <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Looks<span>*</span></label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="looks" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select looks"
                    formControlName="looks" style="color: rgba(0, 0, 0, 0.42)" required>
                    <mat-option *ngFor="let option of budgetInputs?.looks" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="budgetFields.looks.errors && budgetFields.looks.touched">
                <div *ngIf="budgetFields.looks.errors.required" class="error-valid">Num of looks is required</div>
              </div>
            </div> -->

            <div class="purposes" style="margin: 0 0 20px 0;">
              <label>Hold Back</label>
              <div style="text-align:center;">
                <input type="name" *ngIf="this.retask"
                 formControlName="holdBack" required [readonly]="this.retask" />
                <mat-form-field appearance="outline" *ngIf="!this.retask">
                  <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select hold back"
                    formControlName="holdBack" style="color: rgba(0, 0, 0, 0.42)">
                    <mat-option *ngFor="let option of budgetInputs?.holdbackArr" [value]="option">
                      {{capitalizeFirstLetter(option)  }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                
              </div>
              <div *ngIf="budgetFields.holdBack?.errors && budgetFields.holdBack.touched">
                <div *ngIf="budgetFields.holdBack.errors.required" class="error-valid">Hold back is required</div>
              </div>
            </div>
          </form>
        </div>

        <div class="right">
          <div id="wrapper">
            <div class="widget-wrap">
              <div class="tab">
                <input id="tab-1" type="checkbox" />
                <div class="content">
                  <div class="select-head">
                    <div class="preview-box">

                      <div style="font-size: 15px; color: #344054; margin-bottom: 4px;">
                        <span *ngIf="target.area > 0">Area: </span> <span *ngIf="target.area > 0">{{target.area}}
                          Sqkm</span>
                      </div>

                      <div id="cesiumGlobeContainerNew" class="cesiumcontainer" style="
                          width: 49%;
                          height: calc(100vh - 345px) !important;
                        ">
                        <div style="
                            position: absolute;
                            top: 40px;
                            right: 5px;
                            color: white;
                            z-index: 10;
                            height: 10px;
                          ">
                          <p>Search by Lng, Lat or Location</p>
                        </div>
                        <div style="position: absolute; z-index: 1; top: 3px">
                          <div>
                            <button class="cesium-button" title="Place POI as Point"
                              (click)="drawPinOnMap(); keyIn = true" [disabled]="pinDisable || this.retask">
                              <img width="15px" src="../../assets/images/marker-icon.png" />
                            </button>
                          </div>
                          <div>
                            <button class="cesium-button" title="Draw AOI as rectangle" (click)="drawOnMap('rectangle')"
                              [disabled]="buttonDisable || target.buttonDisable || this.retask">
                              <img src="../../assets/images/rectangle.svg" />
                            </button>
                          </div>
                          <div>
                            <button class="cesium-button" title="Draw AOI as polygon" (click)="drawOnMap('polygon')"
                              [disabled]="buttonDisable || target.buttonDisable || this.retask">
                              <img src="../../assets/images/polygon.svg" />
                            </button>
                          </div>
                          <div>
                            <button class="cesium-button" title="Draw AOI as circle" (click)="drawOnMap('circle')"
                              [disabled]="buttonDisable || target.buttonDisable || this.retask">
                              <img src="../../assets/images/circle.svg" />
                            </button>
                          </div>
                          <div>
                            <button id="fileInput" class="cesium-button" (click)="getFileData()"
                              title="Upload kml/json file for AOI" [disabled]="buttonDisable || target.buttonDisable || this.retask">
                              <img src="assets/images/upload-file.svg" />
                            </button>
                            <input type="file" hidden id="filedatas" (change)="onFilesSelected($event)"
                              accept=".geojson,.kml,.kmz,.json" />
                          </div>
                          <div>
                            <button class="cesium-button" title="Measure distance" (click)="drawOnMap('line')"
                            [disabled]="this.retask">
                              <img src="assets/images/measurement.svg" />
                            </button>
                          </div>
                          <div>
                            <button class="cesium-button " title="Clear" (click)="resetMap()" [disabled]="this.retask">
                              <span class='material-icons-outlined'>restart_alt</span>
                            </button>
                          </div>
                        </div>
                        <div style="
                            position: absolute;
                            z-index: 1;
                            bottom: 37px;
                            right: 0px;
                          ">
                          <div>
                            <button class="cesium-button" (click)="zoomIn()">
                              <img width="29px" src="../../assets/images/view-zoom-in.png" />
                            </button>
                          </div>
                          <div>
                            <button class="cesium-button" (click)="zoomOut()">
                              <img width="29px" src="../../assets/images/view-zoom-out.png" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel #cloudPanel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header  (click)="formGroup.valid || !budgetForm?.invalid ? getCloudData(panelOpenState,0) : ''">
        <mat-panel-title>
          <span>Cloud Cover Details <img style="margin-left: 3px; margin-bottom: 3px;" width="15px" [src]="tooltipImagePath"
            matTooltip="Cloud cover forecast is only available for the next 14 days." /></span>
        </mat-panel-title>
        <mat-panel-description>
          Cloud Cover for Tasking
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="tab-panels scrollbars" id="scrollcustom" *ngIf="loadTaskCostDetails() || !budgetForm?.invalid">
        <mat-slide-toggle *ngIf="weatherData.length > 0" [(ngModel)]="isChecked" (change)="getCloudData(panelOpenState,1)"
          style="margin-left: 20px">
          Hourly cloud cover
        </mat-slide-toggle>
        <div class="containers">
        <p *ngIf="weatherData.length === 0 && !this.cloudclicked" class="noData">
          Loading
        </p>
        <p *ngIf="weatherData.length === 0 && this.cloudclicked" class="noData">
          No data available
        </p>
        <div *ngFor="let weather of weatherData" class="flexs">
          <div class="cards" (click)="(isChecked && hourly) ? getHourlyCloudData(panelOpenState,weather) : ''"
          [ngClass]="{ 'cursor-style': (isChecked && hourly) }">
              <div class="climateImage" style="text-align: center;"><img src="../../../assets/weather-icons/{{weather.icon}}.png" alt="icon" style="width: auto; height: 50px"/></div>
              <div class="date">{{ weather?.date ? weather.date : weather?.datetime}}</div>
              <div class="date">{{ weather?.time }}</div>
              <div class="temperature">{{ weather.cloudcover }}%</div>
              <div class="description">{{ weather.description }}</div>
          </div>
        </div>
        </div>
      </div>

      <!-- <app-cloud-cover *ngIf="formGroup.valid && target.geoJSON?.length > 10"
        [target]="target" (resetTasking)="resetMe($event)"></app-cloud-cover> -->


    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngIf="taskStrategy === 'Outcome By Platform' && !retask">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>Outcome Details</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div style="padding: 15px" *ngIf="loadTaskCostDetails()">
        <form class="form-vertical" fxLayout="column" [formGroup]="outComeForm">

          <div fxLayout="row" fxLayoutGap="30px" [formGroup]="outComeForm">
            <div fxLayout="column" fxLayoutGap="18px" class="card-width">
              <div class="purposes" style="margin: 0 0 20px 0;">
                <label class="card-label">Sensor Type<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select sensor type"
                      formControlName="sensorType" (selectionChange)="sensorChange($event.value)" style="color: rgba(0, 0, 0, 0.42)"
                      >
                      <mat-option *ngFor="let option of filteredSensorTypes; let i = index" [value]="option"
                        [disabled]="(target.area > 0 && (option.name === 'SAR' || option.name === 'Video')) || ((target.area === 0 || !target.openWater || target.selectedOption != 2) && option.name === 'AIS')">
                        {{ option.name }}
                        <img style="margin-left: 10px;" width="17px" height="17" [src]="tooltipImagePath"
                          [matTooltip]="option.description"/>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div style="margin: 0 0 20px 0;padding-top: 2px;">
                <label class="card-label">How Soon ?<span> *</span>
                  <span matTooltip="Provide the time by when you would like to have the data from the time of interest (TOI) indicated under frequency" matTooltipClass="custom-option-tooltip">
                  <img style="margin-left: 3px; margin-bottom: 3px;" width="15px" [src]="tooltipImagePath"
                  />
                  </span>
                </label>
                <input type="name" placeholder="Hours" formControlName="latency"
                 maxlength="5" />
                  <div class="error-containers">
                    <span class="error-messages" *ngIf="outComeForm.get('latency').hasError('min')">
                      Minimum hours should be 24.
                    </span>
                    <span class="error-messages" *ngIf="outComeForm.get('latency').hasError('pattern')">
                      &nbsp;Invalid hours
                    </span>
                  </div>   
              </div>  
            </div>
      
            <div fxLayout="column" fxLayoutGap="13px" class="card-width">
              <div class="purposes" style="margin: 0 0 20px 0;" 
                *ngIf="target.taskStrategy === 'Outcome By Platform' && outComeForm.value?.sensorType?.name === 'AIS'; else enableASI">
                <label class="card-label">Update Frequency<span>*</span>
                  <span matTooltip="The frequency at which the AIS data is updated." matTooltipClass="custom-option-tooltip">
                    <img style="margin-left: 3px; margin-bottom: 3px;" width="15px" [src]="tooltipImagePath"
                    />
                    </span>
                </label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select resolution"
                      formControlName="updateFrequency" style="color: rgba(0, 0, 0, 0.42)">
                      <mat-option *ngFor="let option of updateFrequencies; let i = index" [value]="option" (click)="updateFrequencyChange(option)">
                        {{ option.key }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <ng-template #enableASI>
                <div class="purposes" style="margin: 0 0 20px 0;">
                <label class="card-label">Resolution<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select resolution"
                      formControlName="resolution" style="color: rgba(0, 0, 0, 0.42)">
                      <mat-option *ngFor="let option of this.outComesensor?.resolution; let i = index" [value]="option">
                        {{ option.key }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              </ng-template>

              <div style="margin: 0 0 20px 0;padding-top: 7px;" *ngIf="!this.currentUser?.pointsEligible">
                <label class="card-label">Budget ($)<span>*</span></label>
                <input type="name" placeholder="Your budget" formControlName="budget"
                  maxlength="12" />
                  <div class="error-containers">
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('min')">
                      Minimum budget should be $150.
                    </span>
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('pattern')">
                      &nbsp;Invalid budget
                    </span>
                  </div>   
              </div>
              <div style="margin: 0 0 20px 0;padding-top: 7px;" *ngIf="this.currentUser?.pointsEligible">
                <label class="card-label">Budget (Points)<span>*</span></label>
                <input type="name" placeholder="Your budget" formControlName="budget"
                  maxlength="12" />
                  <div class="error-containers">
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('min')">
                      Minimum budget should be {{ convertToPoints(150) }} Points.
                    </span>
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('pattern')">
                      &nbsp;Invalid budget
                    </span>
                  </div>   
              </div>     
            </div>

            <div fxLayout="column" fxLayoutGap="13px" class="card-width">
              <div class="purposes" style="margin: 0 0 20px 0;">
                <label class="card-label">Cloud Cover<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select cloud cover"
                      formControlName="cloudCover" style="color: rgba(0, 0, 0, 0.42)"
                      [disabled]="target.taskStrategy === 'Outcome By Platform' && outComeForm.value?.sensorType?.name === 'AIS'">
                      <mat-option *ngFor="let option of this.outComesensor?.cloudCover; let i = index" [value]="option.key">
                        {{ option.key }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
      
          <div class="profile-btn">
            <button class="custom-button" type="reset">Cancel</button>
            <button mat-flat-button color="primary" [disabled]="demoView || outComeForm.invalid || outComeError
            || (this.outComesensor?.cloudCover?.length > 0 && !this.outComeForm?.value?.cloudCover) || outComeSubmit"
            (click)="submit()">
              Submit
            </button>
          </div>
      
        </form>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel 
      (opened)="panelOpenState = true" 
      (closed)="panelOpenState = false" 
      *ngIf="(taskStrategy !== 'Outcome By Platform' && !retask && !budget) &&
          (subStrategy !== 'Tip-and-Cue-Outcome')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Tasking Cost Details
        </mat-panel-title>
        <mat-panel-description>
          Tasking Costs for Available Operators
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-taskcost *ngIf="loadTaskCostDetails()"
        [target]="target" (resetTasking)="resetMe($event)" (childButtonEvent)="receivedMessageHandler($event)" 
        (childEvent)="clearSatllite()" (resetData)="removeCircles()" (childDataEvent)="receiveHandler($event)"
        (resetRFData)="resetMap()" (resetSpireData)="removeRectangles()"></app-taskcost>
    </mat-expansion-panel>

    <!-- Tip and cue outcome based -->
    <mat-expansion-panel 
      (opened)="panelOpenState = true" 
      (closed)="panelOpenState = false" 
      *ngIf="taskStrategy === 'Tip-and-Cue' && subStrategy == 'Tip-and-Cue-Outcome' && !retask">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>Outcome Details</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div style="padding: 15px" *ngIf="loadTaskCostDetails()">
        <form class="form-vertical" fxLayout="column" [formGroup]="outComeForm">

          <div fxLayout="row" fxLayoutGap="30px" [formGroup]="outComeForm">
            <div fxLayout="column" fxLayoutGap="18px" class="card-width">

              <div style="margin: 0 0 20px 0;">
                <label class="card-label">Based On<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Select an option" panelClass="matopenpurpose"
                      [disableOptionCentering]="true" 
                      formControlName="tipAndCueBasedOn" 
                      (selectionChange)="tipAndCueTypeSelectChange($event)">
                      <mat-option value="Observation">Observation</mat-option>
                      <mat-option value="Rules">Rule</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="purposes" style="margin: 0 0 20px 0;" *ngIf="taskStrategy === 'Outcome By Platform'">
                <label class="card-label">Sensor Type<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select sensor type"
                      formControlName="sensorType" (selectionChange)="sensorChange($event.value)" style="color: rgba(0, 0, 0, 0.42)"
                      >
                      <mat-option *ngFor="let option of filteredSensorTypes; let i = index" [value]="option"
                      [disabled]="target.area > 0 && i === 1">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="purposes" style="margin: 0 0 20px 0;" *ngIf="taskStrategy === 'Tip-and-Cue' && subStrategy == 'Tip-and-Cue-Outcome'">
                <label class="card-label">Tipping Sensor Type<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select tipping sensor type"
                      formControlName="tippingSensorType" (selectionChange)="tippingSensorChange($event.value)" style="color: rgba(0, 0, 0, 0.42)"
                      >
                      <mat-option *ngFor="let option of sensorTypesTipnCue; let i = index" [value]="option">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div style="margin: 0 0 20px 0;padding-top: 2px;">
                <label class="card-label">How Soon ?<span> *</span>
                  <span matTooltip="Provide the time by when you would like to have the data from the time of interest (TOI) indicated under frequency" matTooltipClass="custom-option-tooltip">
                  <img style="margin-left: 3px; margin-bottom: 3px;" width="15px" [src]="tooltipImagePath"
                  />
                  </span>
                </label>
                <input type="name" placeholder="Hours" formControlName="latency"
                 maxlength="5" />
                  <div class="error-containers">
                    <span class="error-messages" *ngIf="outComeForm.get('latency').hasError('min')">
                      Minimum hours should be 24
                    </span>
                    <span class="error-messages" *ngIf="outComeForm.get('latency').hasError('pattern')">
                      Invalid hours
                    </span>
                  </div>   
              </div>  
            </div>
      
            <div fxLayout="column" fxLayoutGap="13px" class="card-width">

              <div style="margin: 0 0 20px 0;" *ngIf="tipAndCueBasedOn === 'Observation'">
                <label class="card-label">Observation</label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline" *ngIf="!this.retask">
                    <mat-select [(ngModel)]="observation"  [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select observation"
                      formControlName="observation"
                      style="color: rgba(0, 0, 0, 0.42);"  (selectionChange)="onObservationChange($event.value)">
                      <mat-option *ngFor="let option of observationOptions" [value]="option" [selected]="observation?.name == option.name"
                      [disabled]="!option?.enabled">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div style="margin: 0 0 20px 0;" *ngIf="tipAndCueBasedOn === 'Rules'">
                <label class="card-label">Rule</label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline" *ngIf="!this.retask">
                    <mat-select [(ngModel)]="rule"  [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select rule"
                      formControlName="rule" 
                      style="color: rgba(0, 0, 0, 0.42);"  (selectionChange)="onRulesChange($event.value)">
                      <mat-option *ngFor="let option of rulesOptions" [value]="option.rule">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="purposes" style="margin: 0 0 20px 0;">
                <label class="card-label">Resolution<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select resolution"
                      formControlName="resolution" style="color: rgba(0, 0, 0, 0.42)">
                      <mat-option *ngFor="let option of this.outComesensor?.resolution; let i = index" [value]="option">
                        {{ option.key }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div style="margin: 0 0 20px 0;padding-top: 7px;" *ngIf="!this.currentUser?.pointsEligible">
                <label class="card-label">Budget ($)<span>*</span></label>
                <input type="name" placeholder="Your budget" formControlName="budget"
                  maxlength="12" />
                  <div class="error-containers">
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('min')">
                      Minimum budget should be $150
                    </span>
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('pattern')">
                      Invalid budget
                    </span>
                  </div>   
              </div>
              <div style="margin: 0 0 20px 0;padding-top: 7px;" *ngIf="this.currentUser?.pointsEligible">
                <label class="card-label">Budget (Points)<span>*</span></label>
                <input type="name" placeholder="Your budget" formControlName="budget"
                  maxlength="12" />
                  <div class="error-containers">
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('min')">
                      Minimum budget should be {{ convertToPoints(150) }} Points
                    </span>
                    <span class="error-messages" *ngIf="outComeForm.get('budget').hasError('pattern')">
                      Invalid budget
                    </span>
                  </div>   
              </div>     
            </div>

            <div fxLayout="column" fxLayoutGap="13px" class="card-width">
              <div class="purposes" style="margin: 0 0 20px 0;" *ngIf="taskStrategy === 'Tip-and-Cue' && subStrategy == 'Tip-and-Cue-Outcome'">
                <label class="card-label">Cueing Sensor Type<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select tipping sensor type"
                      formControlName="cueingSensorType" (selectionChange)="cueingSensorChange($event.value)" style="color: rgba(0, 0, 0, 0.42)"
                      >
                      <mat-option *ngFor="let option of sensorTypesTipnCue; let i = index" [value]="option">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="purposes" style="margin: 0 0 20px 0;">
                <label class="card-label">Cloud Cover<span>*</span></label>
                <div style="text-align:center;">
                  <mat-form-field appearance="outline">
                    <mat-select [disableOptionCentering]="true" panelClass="matopenpurpose" placeholder="Select cloud cover"
                      formControlName="cloudCover" style="color: rgba(0, 0, 0, 0.42)">
                      <mat-option *ngFor="let option of this.outComesensor?.cloudCover; let i = index" [value]="option.key">
                        {{ option.key }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
      
          <div class="profile-btn">
            <button class="custom-button" type="reset">Cancel</button>
            <button *ngIf="target.taskStrategy === 'Tip-and-Cue'" mat-flat-button color="primary" [disabled]="demoView || outComeForm.invalid
            || (this.outComesensor?.cloudCover?.length > 0 && !this.outComeForm?.value?.cloudCover) || outComeSubmit"
            (click)="submit()">
              Submit
            </button>
            <button *ngIf="target.taskStrategy !== 'Tip-and-Cue'" mat-flat-button color="primary" [disabled]="demoView || outComeForm.invalid || outComeError
            || (this.outComesensor?.cloudCover?.length > 0 && !this.outComeForm?.value?.cloudCover) || outComeSubmit"
            (click)="submit()">
              Submit
            </button>
          </div>
      
        </form>
      </div>

    </mat-expansion-panel>
    <!-- Tip and cue outcome based -->

    <div class="profile-btn" *ngIf="retask" style="margin-bottom: 40px;">
      <button mat-flat-button color="primary" [disabled]="demoView || !loadTaskCostDetails() || outComeSubmit"
      (click)="submit()">
        Submit
      </button>
    </div>

    <div class="profile-btn" *ngIf="budget" style="margin-bottom: 40px;">
      <button class="custom-button" type="reset" (click)="resetForm()">Cancel</button>
      <button mat-flat-button color="primary" [disabled]="demoView || budgetForm.invalid || this.target?.geoJSON?.length === 0 || this.target?.geoJSON === null || outComeSubmit
      || budgetEnable ()"
      (click)="submitBudget()">
        Submit
      </button>
    </div>
  </mat-accordion>
</div>
