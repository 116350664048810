import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { PreviescreenComponent } from '../previescreen/previescreen.component';
import { MatDialog } from '@angular/material/dialog';
import { CancelsensorComponent } from '../cancelsensor/cancelsensor.component';
import { AuthService } from 'src/app/services/auth.service';
import { TaskService } from 'src/app/services/task.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToasterService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { CancelpaylaterComponent } from '../payment/cancelpaylater/cancelpaylater.component';
import { DesktopVersionComponent } from './desktop-version/desktop-version.component';
import { environment } from 'src/environments/environment';
import { OPERATORS, STATUS_MAP } from 'src/app/services/constants';
import { ToiWarningPopupComponent } from './toi-warning-popup/toi-warning-popup.component';
import { FeasibilityComponent } from './feasibility/feasibility.component';
import { PopupService } from 'src/app/services/popup.service';
import { CollectionPopupComponent } from '../collections/collection-popup/collection-popup.component';
import { DataService } from 'src/app/services/data.service';
import { CancelinvoiceComponent } from '../orders/cancelinvoice/cancelinvoice.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { CatalogsPopupComponent } from './catalogs-popup/catalogs-popup.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OpportunityComponent } from './opportunity/opportunity.component';
const TASKTABS = [
  { name: 'Recent', value: 'recent', heading: 'Recent Tasks' },
  { name: 'Received', value: 'received', heading: 'Received Tasks' },
  { name: 'Canceled', value: 'canceled', heading: 'Canceled Tasks' },
  { name: 'All', value: 'tasklist', heading: 'All Tasks' },
];

@Component({
  selector: 'app-my-task',
  templateUrl: './my-task.component.html',
  styleUrls: ['./my-task.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class MyTaskComponent implements OnInit, AfterViewInit {
  private cancelPendingRequests = new Subject<void>();
  private latestRequestId = 0;

  panelOpenState: boolean = false;
  icon: boolean = false;
  displayedColumns: string[] = [
    'position',
    'taskname',
    'createddate',
    'startdue',
    'enddue',
    'taskcost',
    'frequency',
    'repeatfrequency',
    'ISTask',
    'button',
    'preview',
    'action',
    'rating'
  ];
  dataSource = new MatTableDataSource<any>();
  tableDataLength: any;
  color = '#1059a9';
  fontweight = '700';
  CARTDATA: any[] = [];

  expandedElement: any;
  currentUser: any;
  curOption: any;
  NAVLISTRT: any;
  tasktabs: any;
  tasksCount: any;
  userid: any;
  selectedSensors: any = [];
  selectedCost = 0;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 50];
  showFirstLastButtons = true;
  interval: any;
  refreshCount = 0;
  taskData: any = [];
  innerDisplayedColumns: string[] = [
    'name',
    'resolution',
    'unit',
    'latency',
    'cloudcover',
    'cost',
    'status',
    'action',
  ];
  innerNameDisplay: string[] = [
    'check',
    'sensorname',
    'taskid',
    'resolution',
    'unit',
    'tasking',
    'cloudcover',
    'firstcapture',
    'lastcapture',
    'sensorcost',
    'oprtyTask',
    'status',
  ];
  columnsToDisplay: string[] = [
    'project',
    'taskname',
    'id',
    'openWindow',
    'closeWindow',
    'taskcost',
    'frequency',
    'repeatFrequency',
    'ISTask',
    'status',
    'preview',
    'action',
  ];
  sortedData: any;
  check = false;
  load: boolean;
  cancelEnabled: boolean = false;
  rejectedEnabled: boolean = false;
  cancelPolicy: any = [];
  detailsText: any = '';
  policy: any;
  tooltipDisabled = true;
  satellogic: boolean = false;
  filterName: string;
  operators: any;
  allTask: boolean = false;
  taskLength: any;
  selectedTasks: any = [];
  order: boolean = false;
  orderPending: boolean = false;
  demoView: boolean = false;
  OPERATORS: any;
  tabSelected: any = 'recent';
  umbra: boolean;
  noFeasibilty: boolean;
  cancelFeasibility: boolean = true;
  head: boolean = false;
  retaskDisable: boolean = true;
  darkTheme: boolean;
  tooltipImagePath: string;
  orbitImage: string;
  allocated: boolean = false;
  isServices: any = '';
  projectData: any = [];
  projectName: any;
  isFilterPanelExpanded: boolean = false;
  taskList: any = [];
  taskName: any;
  selectedProjectId: any;
  selectedTaskId: any;
  currentIndex: number = 1;
  filteredData: any = [];
  balanceRecords: number = 0;
  searchKey: string = '';
  statuses = ['rejected', 'failed', 'completed', 'canceled'];
  customTooltipColumns: string[] = [];
  customTooltipColumnsSR: string[] = [];
  customTooltipColumns1: any[] = ['Signal', 'Collection Duration', 'Cost', 'Audio', 'Audio Cost']
  constructor(
    private matdialog: MatDialog,
    private authService: AuthService,
    private taskService: TaskService,
    private router: Router,
    private apiService: ApiService,
    private toastService: ToasterService,
    private userService: UserService,
    private popupService: PopupService,
    private dataService: DataService
  ) {
    this.dataService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
      this.orbitImage = this.darkTheme ? '../../../assets/images/orbit-dark.png' : '../../../assets/images/orbit.png';
    });

    this.authService.getCurrentUser();
    const castUser = this.authService.castCurrentUser.subscribe(
      async (res: any) => {
        this.currentUser = await res;
        this.setCustomTooltipColumns();
        this.setCustomTooltipColumns1();
        if (this.currentUser?.verified) {
          let userSubs = 'standard';
          this.userService
            .getSensorsPolicy(userSubs, this.currentUser?.id)
            .subscribe((res: any) => {
              this.cancelPolicy = res;
            });
          this.userid = this.currentUser.id;
          if (this.currentUser.userType === 'admin')
            this.userid = this.userid + 'admin';
          this.tasktabs = TASKTABS;
          this.curOption = -1;
          this.getTaskdata(this.userid);
          this.getCartDataVal(this.userid);
        } else {
          this.router.navigate(['login']);
        }
        castUser.unsubscribe();
        this.loadUserDetails(this.currentUser?.id);
      }
    );

    if (environment.demoView === 'true') {
      this.demoView = true;
    } else {
      this.demoView = false;
    }
    this.OPERATORS = OPERATORS;
    localStorage.removeItem('taskData');
  }

  @ViewChild(MatSort) sort: MatSort;

  onCheck() {
    this.check = !this.check;
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  ngOnInit(): void { }
   
  loadUserDetails(userId): void {
    if (userId) {
      this.userService.personalInfo(userId).subscribe({
        next: (res: any) => {
          this.currentUser = res;
          this.setCustomTooltipColumns();
          this.setCustomTooltipColumns1();
          this.allocated = this.currentUser?.allocated;
        },
        error: (error) => {
        }
      });
    }
  }

  getCartDataVal(userId: any) {
    if (this.currentUser?.id) {
      this.apiService.cartdata(this.currentUser.id);
      const res = this.apiService.castCartData.subscribe((result: any) => {
        if (result?.length > 0) {
          this.CARTDATA = result;
        }
      });
      res.unsubscribe();
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.searchKey = filterValue;
    if (!this.searchKey) {
      this.currentIndex = 1;
      this.filteredData = [];
      if (this.selectedProjectId || this.selectedTaskId) this.getData(this.selectedProjectId,this.selectedTaskId);
      else this.getTaskList();
    }
  }

  desktopVersion() {
    this.matdialog.open(DesktopVersionComponent, {
      maxWidth: '80%',
      backdropClass: 'blurred',
      disableClose: true,
    });
  }

  isExpansionDetailRow = (i: number, row: Object) => {
    return row.hasOwnProperty('position');
  };

  getTaskdata(userId: any) {
    if (this.currentUser?.id) {
      this.tableDataLength = 0;
      this.load = true;
      let tab = 'recent';
      if (this.demoView) {
        tab = 'tasklist';
        this.tabSelected = 'tasklist';
      }

      let taskParams = {userid: userId, tabSelected: tab, currentIndex: this.currentIndex}  
      this.taskService.getTask(taskParams).subscribe(
        (result: any) => {
          this.load = false;
          for (const data of result.results) {
            if (data?.outcomeCheck) {
              data.request.length = 1;
            }

            if (data?.taskStrategy === 'Tip-and-Cue' && data.request[0]?.sensorType === 'Tipping') {
              data.request = (data.request).reverse();
            }
          }
          
          this.getTaskStatus(result);
          this.getProjects(); 
        },
        (err) => {
          return false;
        }
      );

      this.apiService.getTaskCount(userId).subscribe((response: any) => {
        if (response?.result > 0) {
          this.allTask = true;
          this.taskLength = response?.result;
        }
      });
    }
  }

  getTask(result) {
    this.retaskDisable = true;
    let retaskDisable = result.request.every(item => this.statuses.includes(item.status));
    if (result?.taskStrategy === 'Task From Collection') this.retaskDisable = true;
    else if (result?.outcomeCheck)  this.retaskDisable = true;
    else if (retaskDisable) this.retaskDisable = false;
    else this.retaskDisable = true;
  }

  getTaskStatus(taskData: any) {
    for (const result of taskData.results) {
      result.retask = true;
      const uniqueStatus = result.request.every(item => item.status === result.request[0].status);
      if (uniqueStatus) {
        const sameStatus = result.request[0]?.status;
        if (sameStatus === 'completed') result.taskCompleted = true;
        else result.taskCompleted = false;

      } else {
        result.taskCompleted = false;
      }

      this.retaskDisable = result.request.every(item => this.statuses.includes(item.status));
      if (result?.taskStrategy === 'Task From Collection') result.retask = true;
      else if (result?.outcomeCheck) result.retask = true;
      else if (this.retaskDisable) result.retask = false;
      else result.retask = true;

      let green = 0,yellow = 0,red = 0;
      for (const item of result.request) {
        const checkstatus = this.statusCount(item?.status);
        if (checkstatus === 'done') green += 1;
        if (checkstatus === 'wip') yellow += 1;
        if (checkstatus === 'canceled') red += 1;
      }
      result.greenCount = green;
      result.yellowCount = yellow;
      result.redCount = red;
    }
    this.balanceRecords = taskData.balance;   
    this.filteredData = this.filteredData.concat(taskData.results);
    this.dataSource = new MatTableDataSource<any>(this.filteredData);
    this.tableDataLength = this.filteredData.length > 0 ? this.filteredData.length : 0;
    this.taskData = this.filteredData.length > 0 ? this.filteredData : [];
    this.taskData.forEach((task:any) => {
      task.grandTotal = parseFloat(task.taskcost) + parseFloat((task.totalVaoCost ?? 0)) + parseFloat((task.totalSRCost ?? 0)) + parseFloat((task.totalIndicesCost ?? 0));
    })
  }

  onItemChange(value: any) {
    this.filterName = '';
    this.tableDataLength = 0;
    this.tabSelected = value.id;
    let tabType = value.id;
    this.searchKey = '';

    this.taskName = '';
    this.projectName = '';
    this.selectedProjectId = '';
    this.selectedTaskId = '';
    this.taskList = [];
    this.currentIndex = 1;
    this.filteredData = [];

    if (value.id === 'all' || (value.id === 'recent' && this.demoView)) {
      tabType = 'tasklist';
      this.tabSelected = 'tasklist';
    }
    this.load = true;
    if (tabType !== 'recent') {
      this.allTask = false;
    } else if (this.taskLength > 0) {
      this.allTask = true;
    }

    this.getProjects();
    this.cancelPendingRequests.next();
    let taskParams = { userid: this.userid, tabSelected: tabType, currentIndex: this.currentIndex };   
    const currentRequestId = ++this.latestRequestId;

    this.taskService.getTask(taskParams)
        .pipe(takeUntil(this.cancelPendingRequests))
        .subscribe((result: any) => {
          // Check if this response is from the latest request
          if (currentRequestId !== this.latestRequestId) {
            return;
          }
          this.load = false;
          for (const data of result.results) {
                  if (data?.outcomeCheck) {
                    data.request.length = 1;
                  }
                  if (data?.taskStrategy === 'Tip-and-Cue' && data.request[0]?.sensorType === 'Tipping') {
                    data.request = (data.request).reverse();
                  }
                }
                this.getTaskStatus(result);  
        }, (err) => {
          return false;
        });
  }

  announceSortChange(sort: Sort) {
    const data = this.taskData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'position':
          return this.compare(a.project, b.project, isAsc);
        case 'taskname':
          return this.compare(a.taskname, b.taskname, isAsc);
        case 'createddate':
          return this.compare(a.createddate, b.createddate, isAsc);
        case 'startdue':
          return this.compare(a.startdue, b.startdue, isAsc);
        case 'enddue':
          return this.compare(a.enddue, b.enddue, isAsc);
        case 'taskcost':
          return this.compare(a.taskcost, b.taskcost, isAsc);
        case 'frequency':
          return this.compare(a.frequency, b.frequency, isAsc);
        case 'repeatfrequency':
          return this.compare(a.repeatfrequency, b.repeatfrequency, isAsc);

        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource<any>(this.sortedData);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  OpenPopup(e: any) {
    if (e.greenCount > 0 || e.yellowCount > 0 || e?.outcomeCheck) {
      this.matdialog.open(PreviescreenComponent, {
        width: '50%',
        data: { taskid: e.id, taskname: e.taskname, target: e.target, sensors: e.request,
          targetPoints: e?.targetPoints
         },
        backdropClass: 'blurred',
        disableClose: true,
      });
    }
  }

  toggleRow(row: any) {
    if (this.expandedElement === row.id) {
      this.expandedElement = null;
    } else {
      this.expandedElement = row.id;
    }
  }

  cancelTask(e: any, index: any, type: any) {
    if (this.tabSelected === 'all') {
      this.tabSelected = 'tasklist';
    }

    e.request.forEach((row) => {
      if (
        (row?.orderStatus === 'ordered' || row?.orderStatus === 'delivering') &&
        row?.checked
      ) {
        type = 'task';
      }
    });

    const dialogRef = this.matdialog.open(CancelsensorComponent, {
      backdropClass: 'blurred',
      disableClose: true,
      data: {
        taskaction: 'taskcancel',
        user: this.currentUser,
        task: e,
        index,
        type,
      },
      maxWidth: '50%',
      minWidth: '35%'
    });
    const indx = this.taskData.findIndex((task) => task.id === e.id);

    dialogRef.afterClosed().subscribe((result) => {
      this.selectedCost = 0;
      (this.selectedTasks = []), (this.selectedSensors = []);
      const indx = this.taskData.findIndex((task) => task.id === e.id);
      this.currentIndex = 1;
      this.filteredData = [];

      if (result?.msg === 'Success' || result === 'Success') {
      if (!this.selectedProjectId && !this.selectedTaskId) {
        this.getTaskList();
      } else {
        this.getData(this.selectedProjectId,this.selectedTaskId);
      }

      this.expandedElement = e.id;

        if (type === 'task') {
          const dialogRef = this.matdialog.open(CancelinvoiceComponent, {
            width: '50%',
            backdropClass: 'blurred',
            disableClose: true,
            data: { taskname: e.taskname },
          });
        }
      } else if (result === 'Failed') {
        const message = 'An error occurred during processing and ingestion of the request. Please try after sometime.';
        this.popupService.openPopup(CollectionPopupComponent, message, '', '50%');
      }
      else {
        this.taskData[indx].request.forEach((oprtr) => {
          oprtr.checked = false;
        });
      }
      this.dataSource = new MatTableDataSource(this.taskData);
      this.dataSource.sort = this.sort;
    });
  }

  taskStatus(e: any, index: number) {
    let operators: any = [];
    e.request.filteredData.forEach((oprtr) => {
      let isOk = this.isTrue(index, oprtr.status);
      if (isOk && index === 999) {
        if (oprtr.status !== 'rejected') oprtr.checked = true;
      }
      if ((oprtr.taskid && isOk) || oprtr.status === 'awaiting') {
        const operator = oprtr.name,
          taskid = oprtr.taskid,
          sensor = oprtr.sensor,
          resolution = oprtr.resolution;
        operators.push({ operator, taskid, sensor, resolution });
      }
    });

    const taskBody = { taskid: e.id, operators: operators };
  }

  taskOrderStatus(e: any, index: number) {
    let operators: any = [];
    e.request.filteredData.forEach((oprtr) => {
      let isOk = this.isTrue(index, oprtr.status);
      if (isOk && index === 999) {
        if (oprtr.status !== 'rejected') oprtr.checked = true;
      }
      if ((oprtr.taskid && isOk) || oprtr.status === 'awaiting') {
        const operator = oprtr.name,
          taskid = oprtr.taskid,
          sensor = oprtr.sensor,
          resolution = oprtr.resolution;
        operators.push({ operator, taskid, sensor, resolution });
      }
    });

    const taskBody = { taskid: e.id, operators: operators };
  }

  isTrue(id: number, status) {
    if (
      status === undefined ||
      status === '' ||
      status === 'canceled' ||
      status === 'rejected' ||
      status === 'awaiting'
    ) {
      return false;
    } else return true;
  }

  formatTaskArea(x: number, y: number): string {
    if (x > y) {
      return `${x}`;
    } else {
      return `${x} (${y})`;
    }
  }

  checkout(el: any, index: any) {
    el.save4later = false;
    this.umbra = false;
    this.noFeasibilty = false;
    this.satellogic = false;
    this.operators = [];
    const endDate = new Date(el.closeWindow);
    const startDate = new Date(el.openWindow);
    const currentDate = new Date();
    this.head = false;
    let checkTime = false;

    el.request.forEach((val) => {
      let approveTime: any;
      let timeDifference = 0, twelveHoursInMillis = 0;

      if (val?.approveTime) {
        approveTime = new Date(val.approveTime);
        timeDifference = currentDate.getTime() - approveTime.getTime();
        twelveHoursInMillis = 12 * 60 * 60 * 1000;
        checkTime = true;
      }

      if (
        val.checked &&
        val.feasibility &&
        (!val?.opportunities || val?.opportunities === 'close')
      ) {
        this.noFeasibilty = true;
      } else if (val.checked && val.operatorKey === OPERATORS.UMBRA) {
        this.umbra = true;
      } else if (val.checked && checkTime && val.operatorKey === OPERATORS.HEAD && timeDifference >= twelveHoursInMillis) {
        this.head = true;
       }
    });
    if (endDate.getTime() < currentDate.getTime()) {
      const indx = this.taskData.findIndex((task) => task.id === el.id);
      const dialogRef = this.matdialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'toi' },
      });
    } else if (startDate.getTime() < currentDate.getTime() && this.umbra) {
      const indx = this.taskData.findIndex((task) => task.id === el.id);
      const dialogRef = this.matdialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'umbra' },
      });
    } else if (this.noFeasibilty) {
      const indx = this.taskData.findIndex((task) => task.id === el.id);      
      const dialogRef = this.matdialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'feasibility' },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
       this.OpenOpportunities(index,this.taskData[indx])
      });
  } else if(this.head) {
    const dialogRef = this.matdialog.open(ToiWarningPopupComponent, {
      width: '50%',
      backdropClass: 'blurred',
      disableClose: true,
      data: { message: 'head' }
    });
    } else {
      el.request.forEach((val) => {
        if(val.operatorKey !== OPERATORS.SPIRE && val.operatorKey !== OPERATORS.CLYDE
          && val.operatorKey !== OPERATORS.LBAND
        ) {
          if (
            (!el?.target.includes('Point') || el?.frequency === 1) &&
            val.checked)  {
            this.satellogic = true;
            const operator = val.name,
              taskid = val.taskid,
              sensor = val.sensor,
              resolution = val.resolution,
              status =
                val.status === 'Ordered' ||
                val.orderStatus === 'ordered' ||
                val.status === '100',
              uuid = val.uuid,
              type = val?.type,
              operatorKey = val?.operatorKey,
              latency = val?.latency;
            this.operators.push({
              operator,
              taskid,
              sensor,
              resolution,
              uuid,
              status,
              latency,
              type,
              operatorKey,
            });
          }
        }
      });

      if (this.satellogic) {
        const dialogRef = this.matdialog.open(CancelpaylaterComponent, {
          width: '50%',
          backdropClass: 'blurred',
          disableClose: true,
          data: { satellogic: this.satellogic },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result === 'yes') {
            const indx = this.taskData.findIndex((task) => task.id === el.id);
            const checkedItems = this.taskData[indx].request.filter(item => item.checked);
            const isISTask = this.taskData[indx].isISTask;

            let selectedSensors: any = [];
            checkedItems.forEach(item => {
              selectedSensors.push(item.sensortype);
            });
            this.apiService
              .getFeedsBySensorType(selectedSensors)
              .subscribe(async (result: any) => {
                if ((result && result?.informationServices?.length)
                  && this.taskData[indx]?.taskStrategy != 'Tip-and-Cue' && this.allocated === false) {
                    const dialogRef = this.matdialog.open(ConfirmPopupComponent, {
                      width: '50%',
                      backdropClass: 'blurred',
                      disableClose: true,
                      data: {message: 'FEED'}
                    });
      
                    dialogRef.afterClosed().subscribe(async (result) => {
                      if (result === 'yes') {
                        const catlogRef = this.matdialog.open(
                          CatalogsPopupComponent,
                          {
                            width: '50%',
                            backdropClass: 'blurred',
                            disableClose: true,
                            data: {taskId: el.id, sensorTypes: selectedSensors, isOpenWater: el.openWater},
                          }
                        );
                        catlogRef.afterClosed().subscribe(async (result) => {
                          if (result !== 'no') {
                            el.mlTasks = result;
                            try {
                              let isAdded = await this.addToCart(el, 'order');
                              if (isAdded) {
                                this.dataService.setSelectedSensors(this.selectedSensors);
                                this.router.navigate(['/payment'], {
                                  queryParams: { taskId: el.id, type: 'task' },
                                });
                              }
                          } catch (error) {
                            console.error("Error adding to cart:", error);
                          }
                          }
                        });
                      } else {
                        el.mlTasks = []
                        try {
                          let isAdded = await this.addToCart(el, 'order');
                          if (isAdded) {
                            this.dataService.setSelectedSensors(this.selectedSensors);
                            this.router.navigate(['/payment'], {
                              queryParams: { taskId: el.id, type: 'task' },
                            });
                          }
                      } catch (error) {
                        console.error("Error adding to cart:", error);
                      }
                      }
                    });
                  } else {
                    try {
                      let isAdded = await this.addToCart(el, 'order');
                      if (isAdded) {
                        this.dataService.setSelectedSensors(this.selectedSensors);
                        this.router.navigate(['/payment'], {
                          queryParams: { taskId: el.id, type: 'task' },
                        });
                      }
                  } catch (error) {
                    console.error("Error adding to cart:", error);
                  }
                  }
              });
          } else if (result === 'no') {
            const action = 'taskcancel';
            this.selectedTasks.length = 0;
          this.selectedSensors.length = 0;
          const taskBody = { taskid: el.id, operators: this.operators };
            const res = await this.apiService
              .cancelSensor(action, taskBody)
              .subscribe((res: any) => {});
            this.apiService.deleteSensor(taskBody).subscribe((res: any) => {});
            const indx = this.taskData.findIndex((task) => task.id === el.id);
            this.selectedCost = 0;
            let completed = 0,
              wip = 0,
              canceled = 0;

            this.taskData[indx].request.forEach((sensor, i = index) => {
              if (sensor.checked) {
                this.taskData[indx].request[i].status = 'canceled';
                this.taskData[indx].request[i].checked = false;
              }
              const chkstatus = this.statusCount(
                this.taskData[indx].request[i].status
              );
              if (chkstatus === 'done') completed += 1;
              if (chkstatus === 'wip') wip += 1;
              if (chkstatus === 'canceled') canceled += 1;
            });
            this.taskData[indx].completed = completed;
            this.taskData[indx].wip = wip;
            this.taskData[indx].canceled = canceled;
            this.dataSource = new MatTableDataSource(this.taskData);
            this.dataSource.sort = this.sort;
          }
        });
      } else {
        //here
        const indx = this.taskData.findIndex((task) => task.id === el.id);
        const checkedItems = this.taskData[indx].request.filter(item => item.checked);
        const isISTask = this.taskData[indx].isISTask

        let selectedSensors: any = [];
        checkedItems.forEach(item => {
          selectedSensors.push(item.sensortype);
        });
        
        this.apiService
          .getFeedsBySensorType(selectedSensors)
          .subscribe(async (result: any) => {
            if ((result && result?.informationServices?.length > 0)
              && this.taskData[indx]?.taskStrategy != 'Tip-and-Cue' && this.allocated === false) {
                const dialogRef = this.matdialog.open(ConfirmPopupComponent, {
                  width: '50%',
                  backdropClass: 'blurred',
                  disableClose: true,
                  data: {message: 'FEED'}
                });
      
                dialogRef.afterClosed().subscribe(async (result) => {
                  if (result === 'yes') {
                    const catlogRef = this.matdialog.open(CatalogsPopupComponent, {
                      width: '50%',
                      backdropClass: 'blurred',
                      disableClose: true,
                      data: {taskId: el.id, sensorTypes: selectedSensors, isOpenWater: el.openWater},
                    });
                    catlogRef.afterClosed().subscribe(async (result) => {
                      if (result !== 'no') {
                        el.mlTasks = result;
                        try {
                          let isAdded = await this.addToCart(el, 'order');
                          if (isAdded) {
                            this.dataService.setSelectedSensors(this.selectedSensors);
                            this.router.navigate(['/payment'], {
                              queryParams: { taskId: el.id, type: 'task' },
                            });
                          }
                      } catch (error) {
                        console.error("Error adding to cart:", error);
                      }
                      }
                    });
                  } else {
                    el.mlTasks = []
                    try {
                      let isAdded = await this.addToCart(el, 'order');
                      if (isAdded) {
                        this.dataService.setSelectedSensors(this.selectedSensors);
                        this.router.navigate(['/payment'], {
                          queryParams: { taskId: el.id, type: 'task' },
                        });
                      }
                  } catch (error) {
                    console.error("Error adding to cart:", error);
                  }
                  }
                });
              } else {
                el.mlTasks = []
                try {
                  let isAdded = await this.addToCart(el, 'order');
                  if (isAdded) {
                    this.dataService.setSelectedSensors(this.selectedSensors);
                    this.router.navigate(['/payment'], {
                      queryParams: { taskId: el.id, type: 'task' },
                    });
                  }
              } catch (error) {
                console.error("Error adding to cart:", error);
              }
              }
          });
      }
    }
  }

  async addToCart(task: any, type: any): Promise<boolean> {
    const endDate = new Date(task.closeWindow);
    const currentDate = new Date();
    this.noFeasibilty = false;
    this.head = false;
    let checkTime = false; 

    let approveTime: any;
    let timeDifference = 0, twelveHoursInMillis = 0;
    task.request.forEach(val => {
      if (val?.approveTime) {
        approveTime = new Date(val.approveTime);
        timeDifference = currentDate.getTime() - approveTime.getTime();
        twelveHoursInMillis = 12 * 60 * 60 * 1000;
        checkTime = true;
      }
      if (val.checked && checkTime && val.operatorKey === OPERATORS.HEAD && timeDifference >= twelveHoursInMillis) {
        this.head = true;
      }
    });
  
    if (endDate.getTime() < currentDate.getTime()) {
      const indx = this.taskData.findIndex((task) => task.id === task.id);
      this.matdialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'toi' }
      });
      return false;
    } else if (this.head) {
      this.matdialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'head' }
      });
      return false;
    } else {
      let selectedSensorTypes: any = [];
      this.selectedSensors.forEach(item => {
        selectedSensorTypes.push(item.sensortype);
      });
  
      const newcartdata = [];
      let mlTasks = [];
      let taskcost = 0;
      this.selectedSensors = [];
  
      try {
        const result: any = await lastValueFrom(this.apiService.getInformationServices(selectedSensorTypes, task.id));
  
        if (result.msg === 'Success') {
          this.load = false;
          const informationServices = result.informationServices;
          mlTasks = informationServices.filter((item) => item.isChecked);
          newcartdata['customerid'] = this.currentUser.id;
          newcartdata['taskid'] = task.id;
          newcartdata['project'] = task.project;
          newcartdata['taskname'] = task.taskname;
          newcartdata['target'] = task.target;
          newcartdata['openWindow'] = task.openWindow;
          newcartdata['closeWindow'] = task.closeWindow;
          newcartdata['checked'] = true;
          newcartdata['taskFrequency'] = task?.frequency;
          newcartdata['mlTasks'] = task?.mlTasks || mlTasks;
          task.request.forEach((sensor) => {
            if (sensor.checked) {
              this.selectedSensors.push(sensor);
              let c = 0;
              
              if (sensor?.super_resolution) {
                c = parseFloat((sensor?.super_resolution[0] || []).totalareacost) ?? 0;
              }
              taskcost += sensor.cost + c;
            }
          });
          this.selectedSensors.forEach(el => {
              if(el?.valueAddedOption) {
                let totalSum = 0;
                Object.keys(el.valueAddedOption).forEach(key => {
                  if (Array.isArray(el.valueAddedOption[key])) {
                    el.valueAddedOption[key].forEach(item => {
                      totalSum += item.totalareacost || 0;
                    });
                  }
                });
                el.demTotalPrice = totalSum;
              }
            });
          newcartdata['cartdata'] = this.selectedSensors;
          newcartdata['taskcost'] = taskcost;
          newcartdata['totalVaoCost'] = task?.totalVaoCost ?? 0;
          newcartdata['totalSRPCost'] = task?.totalSRPCost ?? 0;
          newcartdata['feasibilityTask'] = task?.feasibilityTask;
          newcartdata['taskStrategy'] = task?.taskStrategy;
          newcartdata['purpose'] = task?.purpose;
          newcartdata['intent'] = task?.intent?.key ? task?.intent?.key : task?.intent;
          newcartdata['repeatFrequency'] = task?.repeatFrequency;
          newcartdata['noOfRepeats'] = task?.noOfRepeats;
          newcartdata['subDailyCount'] = task?.subDailyCount;
          newcartdata['projectid'] = task?.projectid;
          newcartdata['openWater'] = task?.openWater;
  
          const res: any = await lastValueFrom(this.apiService.addToCart(this.currentUser.id, newcartdata));
         
          if (type === 'cart') {
            if (res.Msg === 'saved') {
              const toast: any = {
                type: 'success',
                title: '',
                msg: `Task ${task.id} is added in your cart`,
              };
              this.toastService.openSnackBar(toast, 'Show My Cart');
            } else if (res.Msg === 'updated') {
              const toast: any = {
                type: 'success',
                title: '',
                msg: `Task ${task.id} is updated in your cart.`,
              };
              this.toastService.openSnackBar(toast, 'Ok');
            } else if (res.Msg === 'duplication') {
              const toast: any = {
                type: 'warning',
                title: '',
                msg: `Task ${task.id}  already exists in your cart.`,
              };
              this.toastService.openSnackBar(toast, 'Show My Cart');
            }
          }
          return true;
        } else {
          throw new Error('Failed to retrieve information services');
        }
      } catch (error) {
        console.error('Error during addToCart:', error);
        return false;
      }
    }
  }

  selectedTask2Cart(el: any) {
    const newcartdata = [];
    let taskcost = 0;
    this.selectedSensors = [];
    this.CARTDATA = [];
    newcartdata['customerid'] = this.currentUser.id;
    newcartdata['taskid'] = el.id;
    newcartdata['project'] = el.project;
    newcartdata['taskname'] = el.taskname;
    newcartdata['target'] = el.target;
    newcartdata['openWindow'] = el.openWindow;
    newcartdata['closeWindow'] = el.closeWindow;
    newcartdata['checked'] = true;
    newcartdata['taskFrequency'] = el?.frequency;
    newcartdata['mlTasks'] = el?.mlTasks;
    // newcartdata['isISTask'] = el?.isISTask;
    if (this.CARTDATA && this.CARTDATA.length > 0)
      this.CARTDATA.forEach((task, index) => {
        this.CARTDATA[index].checked = false;
      });
    el.request.forEach((sensor) => {
      if (sensor.checked) {
        this.selectedSensors.push(sensor);
        let c = 0;
        if (sensor?.super_resolution) {
          c = parseFloat((sensor?.super_resolution[0] || []).totalareacost) ?? 0;
        }
        taskcost += sensor.cost + c;
      }
    });
    this.selectedSensors.forEach(el => {
      if(el?.valueAddedOption) {
        let totalSum = 0;
        Object.keys(el.valueAddedOption).forEach(key => {
          if (Array.isArray(el.valueAddedOption[key])) {
            el.valueAddedOption[key].forEach(item => {
              totalSum += item.totalareacost || 0;
            });
          }
        });
        el.demTotalPrice = totalSum;
      }
    });
    
    newcartdata['cartdata'] = this.selectedSensors;
    newcartdata['taskcost'] = taskcost;
    newcartdata['totalVaoCost'] = el?.totalVaoCost ?? 0;
    newcartdata['totalSRPCost'] = el?.totalSRPCost ?? 0;
    this.CARTDATA.push(newcartdata);
    this.apiService.updateCartDB(this.currentUser.id, newcartdata);
    this.apiService.cartBS.next(this.CARTDATA);
  }

  add2cart(el: any) {
    const endDate = new Date(el.closeWindow);
    const currentDate = new Date();
    if (endDate.getTime() < currentDate.getTime()) {
      const indx = this.taskData.findIndex((task) => task.id === el.id);
      const dialogRef = this.matdialog.open(ToiWarningPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'toi' },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.taskData[indx].request.forEach((oprtr) => {
          oprtr.checked = false;
        });
      });
    } else {
      el.save4later = true;
      const indx = this.CARTDATA.findIndex((elem) => elem.taskid === el.id);
      if (indx > -1) {
        let newdata: any = [],
          notInCart: any = [],
          taskcost = 0;
        let sensorDetail = '';
        el.request.forEach((row) => {
          if (row.checked) {
            newdata.push(row);
            let c = 0;
              if (row?.super_resolution) {
                c = parseFloat((row?.super_resolution[0] || []).totalareacost) ?? 0;
              }
              taskcost += row.cost + c;
            const id = this.CARTDATA[indx].cartdata.findIndex(
              (itm) => itm.taskid === row.taskid
            );
            if (id === -1) {
              notInCart.push(row);
              sensorDetail = ``;
            }
          }
        });

        if (notInCart.length === 0) {
          const toast: any = {
            type: 'warning',
            title: '',
            msg: `Task ${el.id}  already exists in Your Cart.${sensorDetail}`,
          };
          this.toastService.openSnackBar(toast, 'Show My Cart');
        } else {
          this.CARTDATA.splice(indx, 1);
          const toast: any = {
            type: 'success',
            title: '',
            msg: `Task ${el.id} is replaced in Your Cart.${sensorDetail}`,
          };
          this.toastService.openSnackBar(toast, 'Ok');
          this.selectedTask2Cart(el);
        }
      } else {
        const toast: any = {
          type: 'success',
          title: '',
          msg: `Task ${el.id} is added in Your Cart`,
        };
        this.toastService.openSnackBar(toast, 'Show My Cart');
        this.selectedTask2Cart(el);
      }
    }
  }

  getTaskCost(checked: boolean, el: any, i: any) {
    (this.selectedCost = 0),
      ((this.selectedSensors = []),
      (this.selectedTasks = []),
      (this.order = false),
      (this.cancelFeasibility = true));
    el.request[i].checked = checked;
    el.request.forEach((row) => {
      if (row.checked) {
        if (
          row?.orderStatus === 'ordered' ||
          row.status === 'awaiting' ||
          row.status === 'delivering'
        ) {
          this.order = true;
        }
        this.selectedCost += row.cost;
        this.selectedTasks.push(row);
      }
    });

    for (const row of this.selectedTasks) {
      if (
        (row?.orderStatus === 'ordered' || row.status === 'ordered') &&
        row?.operatorKey === OPERATORS.AXELSPACE
      ) {
        this.cancelFeasibility = false;
      } else this.cancelFeasibility = true;
    }

    el.request.forEach((row) => {
      if (
        row.checked &&
        !this.order &&
        row?.orderStatus != 'ordered' &&
        row.status !== 'awaiting' &&
        row.status !== 'delivering' &&
        row.status !== 'archived' &&
        row.status !== 'restored'
      ) {
        this.selectedSensors.push(row);
      }
    });
    const orderPendingCount = this.selectedSensors.filter(item => item.status === 'pending').length;
    this.orderPending = !!orderPendingCount;
  }

  deselect(e) {
    this.selectedCost = 0;
    this.selectedTasks = [];
    this.selectedSensors = [];
    this.order = false;
    const indx = this.taskData.findIndex((task) => task.id === e.id);
    this.taskData[indx].request.forEach((oprtr) => {
      oprtr.checked = false;
      if (oprtr.signalOfInterest) {
        const hasAudioSignal = oprtr.signalOfInterest.some(signal => signal.isAudio === true);
        oprtr.isAudio = hasAudioSignal;
      }
    });
  }

  go2Route(id: any) {
    this.NAVLISTRT.forEach((item, index) => {
      if (id === index) {
        this.NAVLISTRT[index].active = true;
      } else {
        this.NAVLISTRT[index].active = false;
      }
    });
    this.router.navigate([this.NAVLISTRT[id].url]);
  }

  selections(element: any) {
    this.tooltipDisabled = false;
    if (element.operatorKey === OPERATORS.SPIRE) {
      this.detailsText = 'No cancelation is permitted once refresh is ordered'
    } else if (element.operatorKey === OPERATORS.CLYDE) {
      this.detailsText = "The sensor can be canceled anytime after two months with one month's" + '\n' +  "notice on the minimum subscription of three months. Similarly," + '\n' + "all subscriptions require one month's notice.";
    } else {
      for (var i = 0; i < this.cancelPolicy.length; i++) {
        if (
          element.operatorKey === this.cancelPolicy[i].key &&
          element.sensor === this.cancelPolicy[i].sensor
        ) {
          this.policy = this.cancelPolicy[i].cancel_policy;
          this.policy.forEach((d) => {
            this.detailsText += 'Cancelation ' + d.key + ' : ' + d.value + '\n';
          });
        }
      }
    }
  }

  onMouseOut() {
    this.tooltipDisabled = true;
    this.detailsText = ' ';
  }

  OpenFeasibilty(element: any, index: any, task: any) {
    const dialog = this.matdialog.open(FeasibilityComponent, {
      minWidth: '68%',
      data: {
        row: element,
        user: this.userid,
        taskid: task.id,
        taskList: task,
      },
      backdropClass: 'blurred',
      disableClose: true,
    });

    const indx = this.taskData.findIndex((elem) => elem.id === task.id);
    if (this.tabSelected === 'all') {
      this.tabSelected = 'tasklist';
    }

    dialog.afterClosed().subscribe((result) => {
      if (result != 'close') {
        this.taskData[indx].request[index].opportunities = result;
        if (element?.operatorKey === OPERATORS.AXELSPACE) {
          this.apiService
            .getFeasibilityCost(this.userid, result, task.id, element)
            .subscribe((result: any) => {
              this.taskData[indx].request[index].cost = result?.sensorCost;
              this.taskData[indx].taskcost = result?.totalCost;
            });
        }
        this.apiService.saveOpportunity(this.userid, task.id, [element]).subscribe(
          (result: any) => {
            if (result.msg === 'Success') {
              this.taskData[indx].request[index].firstCapture = result?.captures[0].firstCapture;
              this.taskData[indx].request[index].lastCapture = result?.captures[0].lastCapture;
            }
          },
          (err) => {
            return false;
          }
        );
      } else {
      this.currentIndex = 1;
      this.filteredData = [];
      if (!this.selectedProjectId && !this.selectedTaskId) {
        this.getTaskList();
      } else {
        this.getData(this.selectedProjectId,this.selectedTaskId);
      }

        this.expandedElement = task.id;
        this.selectedCost = 0;
        this.selectedTasks = [];
        this.selectedSensors = [];
      }
    });
  }

  cancelOpportunities(details) {
    this.operators = [];
    details.request.forEach((val) => {
      if (val.checked) {
        const operator = val.name,
          taskid = val.taskid,
          sensor = val.sensor,
          resolution = val.resolution,
          status =
            val.status === 'Ordered' ||
            val.orderStatus === 'ordered' ||
            val.status === '100',
          uuid = val.uuid,
          type = val?.type,
          operatorKey = val?.operatorKey,
          latency = val?.latency;
        this.operators.push({
          operator,
          taskid,
          sensor,
          resolution,
          uuid,
          status,
          latency,
          type,
          operatorKey,
        });
      }
    });

    const taskBody = { taskid: details.id, operators: this.operators };
    const dialog = this.matdialog.open(FeasibilityComponent, {
      minWidth: '68%',
      data: { task: details, taskBody: taskBody },
      backdropClass: 'blurred',
      disableClose: true,
    });

    dialog.afterClosed().subscribe((result) => {
      if (result.msg === 'Success') {
      this.currentIndex = 1;
      this.filteredData = [];
      if (!this.selectedProjectId && !this.selectedTaskId) {
        this.getTaskList();
      } else {
        this.getData(this.selectedProjectId,this.selectedTaskId);
      }

      this.expandedElement = details.id;
      this.selectedCost = 0;
      this.selectedTasks = [];
      this.selectedSensors = [];
      }
    });
  }

  getRetask(element: any) {       
    this.dataService.setSelectedTask(JSON.stringify(element));
    localStorage.setItem('rowData', JSON.stringify(element));       
    this.router.navigate(['/retask']);      
  }

  getProjects() {
    this.apiService.getProjectList(this.tabSelected).subscribe((response: any) => {
      this.projectData = response.result;
    });
  }

  selectProject (projects: any) {
    this.projectName = projects?.project;
    this.selectedProjectId = projects?.projectid;
    this.taskName = '';
    this.selectedTaskId = '';
    this.currentIndex = 1;
    this.filteredData = [];
    this.getData(this.selectedProjectId,'');
    this.apiService.getTaskList(this.selectedProjectId, this.tabSelected).subscribe((response: any) => {
      this.taskList = response.result;
    });  
  }

  selectTask (tasks: any) {
    this.taskName = tasks?.taskName;
    this.selectedTaskId = tasks?.id;
    this.currentIndex = 1;
    this.filteredData = [];
    this.getData(this.selectedProjectId,this.selectedTaskId);
  }

  toggleFilterPanel() {
    this.isFilterPanelExpanded = !this.isFilterPanelExpanded;    
  }

  clearFilter() {
    this.taskName = '';
    this.projectName = '';
    this.selectedProjectId = '';
    this.selectedTaskId = '';
    this.currentIndex = 1;
    this.filteredData = [];
    this.getTaskList();
  }

  getData (projectId: any, taskId: any) {
    let taskParams = {projectId: projectId, tabSelected: this.tabSelected, taskId: taskId, currentIndex: this.currentIndex, searchKey: this.searchKey};  
    this.apiService.getData(taskParams).subscribe((response: any) => {
      this.getTaskStatus(response);
    });
  }

  getTaskList() {
    let taskParams = {userid: this.userid, tabSelected: this.tabSelected, currentIndex: this.currentIndex, searchKey: this.searchKey};  
    this.taskService.getTask(taskParams).subscribe(
      (result: any) => {
        this.load = false;
          for (const data of result.results) {
            if (data?.outcomeCheck) {
              data.request.length = 1;
            }
            if (data?.taskStrategy === 'Tip-and-Cue' && data.request[0]?.sensorType === 'Tipping') {
              data.request = (data.request).reverse();
            }
          }
        this.getTaskStatus(result);
        this.taskList = [];
      },
      (err) => {
        return false;
      }
    );
  }

  getIsTooltipText(list: any[]) {
    this.isServices = '';
    if(list?.length > 0) {
      this.isServices = list?.reduce((acc, curr) => {
        acc = acc + curr.name + '\n';
        return acc;
      }, '');
    }
  }

rateTask(task: any) {
  const index = this.taskData.findIndex((elem) => elem.id === task.id);
  const dialog = this.matdialog.open(StarRatingComponent, {
    width: '45%',
    data: { task: task},
    backdropClass: 'blurred',
    disableClose: true,
  });

  dialog.afterClosed().subscribe((result) => {
    if (result != 'close') {
        let feedbackObj = {
          rating: result.rating,
          feedback: result.feedback
        }
        this.taskData[index].feedback = feedbackObj;
    }
  });
}

handleButtonClick(event: Event) {
  event.stopPropagation();
}

loadMoreData() {
  if (this.balanceRecords > 0) {
    this.currentIndex += 1;
    if (!this.selectedProjectId && !this.selectedTaskId) this.getTaskList();
    else this.getData(this.selectedProjectId,this.selectedTaskId);
  }
}

statusCount(status: any) {
  if (status) {
    for (const key in STATUS_MAP) {
      if (status.includes(key)) {
        return STATUS_MAP[key];
      }
    }
  }
  return;
}

formatTooltip(element: any) {  
  this.tooltipDisabled = false;
  element.forEach((data) => {
    this.detailsText += data + '\n';
  });  
}

OpenOpportunities(index: any, task: any) {
  let selectedRows: any = [];
  let sensorDetails = task.request.filter(row => (row.checked));
  if (sensorDetails?.length === 1) {
    const checkedItemIndex = task.request.findIndex(row => row.checked);
    this.OpenFeasibilty(sensorDetails[0], checkedItemIndex, task);
  } else {

  const dialog = this.matdialog.open(OpportunityComponent, {
    minWidth: '68%',
    data: {
      user: this.userid,
      taskid: task.id,
      taskList: task,
    },
    backdropClass: 'blurred',
    disableClose: true,
  });

  const indx = this.taskData.findIndex((elem) => elem.id === task.id);
  if (this.tabSelected === 'all') {
    this.tabSelected = 'tasklist';
  }

  dialog.afterClosed().subscribe((result) => {
    if (result != 'close') {
      let selectedOpportunities = result;
      selectedOpportunities.forEach((enteredRow) => {
        const correspondingRequest = this.taskData[indx].request.find(request => request.uuid === enteredRow.sensorDetail.uuid);
        if (correspondingRequest) {
          correspondingRequest.opportunities = enteredRow.enteredPreferences;
        }

        selectedRows.push(correspondingRequest);
      });

      if (selectedRows[0]?.operatorKey === OPERATORS.AXELSPACE) {
        this.apiService
          .getFeasibilityCost(this.userid, selectedOpportunities[0].enteredPreferences, task.id, selectedRows[0])
          .subscribe((result: any) => {
            selectedOpportunities.forEach((enteredRow) => {
              const correspondingRequest = this.taskData[indx].request.find(request => request.uuid === enteredRow.sensorDetail.uuid);
              if (correspondingRequest) {
                correspondingRequest.cost = result?.sensorCost;
                this.taskData[indx].taskcost = result?.totalCost;
              }     
            });
          });
      }
      this.apiService.saveOpportunity(this.userid, task.id, selectedRows).subscribe(
        (result: any) => {
          if (result.msg === 'Success') {
            result?.captures.forEach((enteredRow) => {
              const correspondingRequest = this.taskData[indx].request.find(request => request.uuid === enteredRow.sensorId);
              if (correspondingRequest) {
                correspondingRequest.firstCapture = enteredRow.firstCapture;
                correspondingRequest.lastCapture = enteredRow.lastCapture;
              }     
            });
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
    this.currentIndex = 1;
    this.filteredData = [];
    if (!this.selectedProjectId && !this.selectedTaskId) {
      this.getTaskList();
    } else {
      this.getData(this.selectedProjectId,this.selectedTaskId);
    }

      this.expandedElement = task.id;
      this.selectedCost = 0;
      this.selectedTasks = [];
      this.selectedSensors = [];
    }
  });
 }
}

hasValueAddedOptionKeys(obj:any)
{
  if(obj.valueAddedOption && Object.keys(obj.valueAddedOption).length > 0 || obj?.super_resolution?.length > 0 || obj?.indices?.length > 0) return true;
  return false;
}
setCustomTooltipColumns() {
  if (this.currentUser.pointsEligible) {
    this.customTooltipColumns = [ 'actionProvider', 'sensorResolution', 'deliveryTime', 'point'];
    this.customTooltipColumnsSR = [ 'sensorResolution', 'deliveryTime', 'point'];
  } else {
    this.customTooltipColumns =  ['actionProvider', 'sensorResolution', 'deliveryTime', 'cost'];
    this.customTooltipColumnsSR =  ['sensorResolution', 'deliveryTime', 'cost'];
  }
}

setCustomTooltipColumns1() {
  if (this.currentUser.pointsEligible) {
    this.customTooltipColumns1 = ['Signal', 'Collection Duration', 'Point', 'Audio', 'Audio Point'];
  } else {
    this.customTooltipColumns1 =  ['Signal', 'Collection Duration', 'Cost', 'Audio', 'Audio Cost']
  }
}
getDataRows(element:any) {
  const rows = Object.values(element?.valueAddedOption).flat();

   rows.sort((a, b) => {
    const actionA = (a as any).actionProvider || '';
    const actionB = (b as any).actionProvider || '';
    return actionA.localeCompare(actionB);
  });

  return [
    ...rows,
    ...(Array.isArray(element.super_resolution) ? element.super_resolution : []),
    ...(Array.isArray(element.indices) ? element.indices : [])
  ];
}
}
